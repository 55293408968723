import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query/react';
import { ENV } from '../constants';
import { RootState } from '../types/State';
import { appActions } from '../store/app';
import i18next from 'i18next';

export const appInvalidateTags = ['Users', 'Orders'];

export const apiTags = [
  'AuthToken',
  'AuthUser',
  'AuthCustomer',
  'Users',
  'Organization',
  'OrganizationConfiguration',
  'OrganizationActivities',
  'Roundtrips',
  'Vehicles',
  'Places',
  'Activities',
  'RoundtripByAgent',
  'StatsOrders',
  'Files',
  'Orders',
  'OrderActivity',
  'Conversations',
  'RoundtripsStats',
  'RoundtripDetails',
  'Templates',
  'RoundtripsCalendar',
  'Customer',
  'Customers',
  'Products',
  'Chats',
  'Calls',
  'OrderFacets',
  'AccessoryEvents',
  'Accessories',
  'Stats',
  'AiPipelines',
  'AiTriggers',
  'Units',
  'LeaveRequests',
  'LeaveRequestTypes',
];

const customBackOff = async (attempt = 0, maxRetries = 5) => {
  const attempts = Math.min(attempt, maxRetries);

  let delay = 400;

  if (attempt > 600) {
    delay = 2000;
  } else if (attempt > 5) {
    delay = 1000;
  }

  await new Promise(resolve => {
    setTimeout(resolve, attempts * delay);
  });
};

export function configureApi(baseUrl: string | undefined) {
  return createApi({
    reducerPath: 'api',
    baseQuery: retry(
      async (args: any, api, extraOptions) => {
        const authUser = (api.getState() as RootState).auth?.user;
        const token = (api.getState() as RootState).auth?.token;
        const currentApp = (api.getState() as RootState).app?.currentApplication
          ?.id;
        let inSettings = false;
        try {
          inSettings = window.location.pathname?.includes('/settings');
        } catch (err) {}

        const authApp = inSettings ? 'idm' : currentApp;

        if (authUser) {
          const authAppParam = `authApplication=${authApp}`;
          if (typeof args === 'object') {
            // Only add authApplication if it's not already in the URL
            if (!args.url.includes('authApplication')) {
              if (args.url.indexOf('?') > -1) {
                args.url = `${args.url}&${authAppParam}`;
              } else {
                args.url = `${args.url}?${authAppParam}`;
              }
            }
          } else if (typeof args === 'string') {
            if (!args.includes('authApplication')) {
              if (args.indexOf('?') > -1) {
                args = `${args}&${authAppParam}`;
              } else {
                args = `${args}?${authAppParam}`;
              }
            }
          }
        }

        const result = await fetchBaseQuery({
          baseUrl: baseUrl,
          prepareHeaders: headers => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            if (token) {
              headers.set('authorization', `Bearer ${token}`);
            }

            return headers;
          },
        })(args, api, extraOptions);

        let errorMessage = '';

        if (result.error && result.error.status === 400) {
          //@ts-ignore
          errorMessage = result?.error?.data?.message;
        } else if (result?.error?.status === 403) {
          errorMessage = i18next.t('unauthorized_message');
        } else if (
          result?.error?.status === 500 ||
          //@ts-ignore
          result?.error?.originalStatus === 500
        ) {
          errorMessage = i18next.t('server_error_message');
        }

        if (errorMessage) {
          //@ts-ignore
          if (appActions) {
            api.dispatch(
              appActions.setLastToasterNotification({
                duration: 3000,
                severity: 'error',
                //@ts-ignore
                message: i18next.t(errorMessage),
              }),
            );
          }
        }

        // bail out of re-tries immediately if unauthorized,
        // because we know successive re-retries would be redundant
        if (result.error?.status && result.error?.status !== 'FETCH_ERROR') {
          retry.fail(result.error);
        }

        return result;
      },
      {
        backoff: customBackOff,
        maxRetries: 1,
      },
    ),
    tagTypes: apiTags,
    endpoints: builder => ({
      ping: builder.query<any, void>({
        query: () => ({
          url: '/',
        }),
        extraOptions: {
          refetchOnMountOrArgChange: true,
        },
      }),
    }),
  });
}

const api = configureApi(ENV.API_URL);
export const { usePingQuery } = api;
export default api;
