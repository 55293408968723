import { getOrderDto } from '../Dtos/mobile/getOrderDto';
import { getOrderIdDto } from '../Dtos/mobile/getOrderIdDto';
import api from './api';

const MAX_RETRY = 1200;

export const ordersApis = api.injectEndpoints({
  endpoints: builder => ({
    getOrders: builder.query<any, string>({
      query: urlQuery => ({
        url: `/orders${urlQuery}`,
      }),
      providesTags: ['Orders'],
      transformResponse: (response: any, meta, arg) => {
        const urlParams = new URLSearchParams(arg);
        const dto = urlParams.get('dto');
        if (dto === 'mobile') return getOrderDto(response);

        return response;
      },
    }),
    getOrderFacets: builder.query<any, string>({
      query: urlQuery => ({
        url: `/orders/facets${urlQuery}`,
      }),
      providesTags: ['Orders', 'OrderFacets'],
    }),
    getOrdersId: builder.query<any, string>({
      query: id => ({
        url: `/orders/${id}`,
      }),
      providesTags: (result, err, arg) => [
        'Orders',
        { type: 'Orders', id: arg },
      ],
      transformResponse: (response: any, meta, arg) => {
        const queryString = arg?.split('?')[1];
        const urlParams = new URLSearchParams(queryString);
        const dto = urlParams.get('dto');
        if (dto === 'mobile') return getOrderIdDto(response);
        return response;
      },
      extraOptions: { maxRetries: 1 },
    }),
    getOrderActivity: builder.query<any, any>({
      query: ({ id, query }) => ({
        url: `/orders/${id}/activities?${query ?? ''}`,
      }),
      providesTags: (result, err, arg) => [
        'OrderActivity',
        { type: 'OrderActivity', id: arg },
      ],
      extraOptions: { maxRetries: 1 },
    }),
    getOrderRoundTrips: builder.query({
      query: ({ id, query }) => ({
        url: `/orders/${id}/roundtrips${query ?? ''}`,
      }),
      providesTags: (result, err, arg) => [
        'OrderRoundtrip',
        { type: 'OrderRoundtrip', id: arg },
      ],
      extraOptions: { maxRetries: 1 },
    }),
    getOrderRoundTripsBulk: builder.query({
      query: ({ query }) => ({
        url: `/orders/roundtrips${query ?? ''}`,
      }),
      providesTags: (result, err, arg) => [
        'OrderRoundtrips',
        { type: 'OrderRoundtrips', id: arg },
      ],
      extraOptions: { maxRetries: 1 },
    }),
    updateOrderStatusBulk: builder.mutation<any, any>({
      query: body => ({
        url: `/orders/status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['OrdersStatusBulk'],
      onQueryStarted: async (
        { queryParams: urlQuery }: any,
        { dispatch, queryFulfilled }: any,
      ) => {
        try {
          const { data: updatedPost } = await queryFulfilled;
          dispatch(
            ordersApis.util.updateQueryData('getOrders', urlQuery, draft => {
              updatedPost?.map(respOrder => {
                draft.docs = draft.docs.map(order => {
                  if (order?._id === respOrder?._id) {
                    return { ...respOrder };
                  }
                  return order;
                });
              });
            }),
          );
        } catch {}
      },
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    uncancelOrder: builder.mutation<any, any>({
      query: body => ({
        url: `/orders/${body.id}/uncancelOrder`,
        method: 'PATCH',
        body,
      }),
    }),
    updateOrder: builder.mutation<any, any>({
      query: body => ({
        url: `/orders/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Roundtrips', 'OrderFacets'],
      onQueryStarted: async (
        { id, queryParams: urlQuery }: any,
        { dispatch, queryFulfilled }: any,
      ) => {
        try {
          const { data: updatedPost } = await queryFulfilled;
          dispatch(
            ordersApis.util.updateQueryData('getOrders', urlQuery, draft => {
              draft.docs = draft.docs.map(order => {
                if (order._id === id) {
                  return { ...updatedPost };
                }
                return order;
              });
            }),
          );
        } catch {}
      },
    }),
    updateOrderSupportUnits: builder.mutation<any, any>({
      query: body => ({
        url: `/orders/${body.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    updateOrderDeliveryInfo: builder.mutation<any, any>({
      query: body => ({
        url: `/orders/${body.id}/delivery-info`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Orders'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    updateOrdersDate: builder.mutation<any, any>({
      query: body => ({
        url: `/orders/delivery-date`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Roundtrips', 'Orders'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    updateOrderDeliverySignature: builder.mutation<any, any>({
      query: ({ id, formData }: any) => {
        return {
          url: `/orders/${id}/delivery-signature`,
          method: 'PATCH',
          body: formData,
        };
      },
    }),
    deleteSignature: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/orders/${id}/delete-signature`,
          method: 'DELETE',
        };
      },
    }),
    createOrder: builder.mutation<
      any,
      {
        deliveryDate: string;
        type: string;
        deck: string;
        templateId: string | null;
        products: Array<Object>;
        linkedDecks: Array<string>;
        customerId: string;
      }
    >({
      query: body => ({
        url: '/orders',
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    updateCustomerOrder: builder.mutation<any, any>({
      query: ({ id, formData }: any) => ({
        url: `/orders/${id}/customer-order`,
        method: 'PATCH',
        body: formData,
      }),
      extraOptions: { maxRetries: 0 },
      onQueryStarted: async (
        { id },
        { dispatch, queryFulfilled, getState },
      ) => {
        try {
          const { data: updatedPost } = await queryFulfilled;

          const queries = ordersApis.util
            .selectInvalidatedBy(getState(), ['Orders'])
            ?.filter(query => query?.endpointName === 'getOrders');

          queries?.forEach(({ originalArgs }) => {
            dispatch(
              ordersApis.util.updateQueryData(
                'getOrders',
                originalArgs,
                draft => {
                  draft.docs = draft.docs.map(order => {
                    if (order._id === id) {
                      return { ...updatedPost };
                    }
                    return order;
                  });
                },
              ),
            );
          });
        } catch {}
      },
      invalidatesTags: ['Order', 'OrderFacets', 'Roundtrips'],
    }),
    getProductNotes: builder.query<any, { orderId: string; productId: string }>(
      {
        query: ({ orderId, productId }) => ({
          url: `orders/${orderId}/notes?productId=${productId}`,
          method: 'GET',
        }),
      },
    ),
    createProductNote: builder.mutation<
      void,
      { orderId: string; content: string; productId?: string }
    >({
      query: ({ orderId, content, productId }) => ({
        url: `orders/${orderId}/notes`,
        method: 'POST',
        body: { content, productId },
      }),
    }),
    deleteProductNote: builder.mutation<
      any,
      { orderId: string; noteId: string }
    >({
      query: ({ orderId, noteId }) => ({
        url: `orders/${orderId}/notes/${noteId}`,
        method: 'DELETE',
      }),
    }),
    patchProductNote: builder.mutation<
      any,
      { orderId: string; noteId: string; content: string }
    >({
      query: ({ orderId, noteId, content }) => ({
        url: `orders/${orderId}/notes/${noteId}`,
        method: 'PATCH',
        body: { content },
      }),
    }),
    createTemplate: builder.mutation<any, any>({
      query: ({ formData }: any) => {
        return {
          url: `/templates`,
          method: 'POST',
          body: formData,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    getTemplates: builder.query<any, string>({
      query: () => ({
        url: `/templates`,
      }),
    }),
    getFrequentlyOrdered: builder.query<any, string>({
      query: () => ({
        url: `/orders/frequently-ordered-items`,
        method: 'GET',
      }),
    }),
    addPhoneBook: builder.mutation<any, any>({
      query: ({ orderId, ...body }) => ({
        url: `orders/${orderId}/phone-book`,
        method: 'POST',
        body,
      }),
    }),
    updatePhoneBook: builder.mutation<any, any>({
      query: ({ orderId, phoneBookId, ...body }) => ({
        url: `orders/${orderId}/${phoneBookId}/phone-book`,
        method: 'PUT',
        body,
      }),
    }),
    deletePhoneBook: builder.mutation<any, any>({
      query: ({ orderId, phoneBookId }) => ({
        url: `orders/${orderId}/${phoneBookId}/phone-book`,
        method: 'DELETE',
      }),
    }),
    getOrderComment: builder.query<any, any>({
      query: ({ orderId, commentId }) => ({
        url: `orders/${orderId}/comments?id=${commentId}`,
        method: 'GET',
      }),
    }),
    addOrderComment: builder.mutation<any, any>({
      query: ({ orderId, ...body }) => ({
        url: `orders/${orderId}/comments`,
        method: 'POST',
        body,
      }),
    }),
    editOrderComment: builder.mutation<any, any>({
      query: ({ orderId, commentId, ...body }) => ({
        url: `orders/${orderId}/comments/${commentId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteOrderComment: builder.mutation<any, any>({
      query: ({ orderId, commentId }) => ({
        url: `orders/${orderId}/comments/${commentId}`,
        method: 'DELETE',
      }),
    }),
    getActiveOrder: builder.query<any, { customerId: string }>({
      query: ({ customerId }) => ({
        url: `orders/active-orders/${customerId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetActiveOrderQuery,
  useUpdateOrdersDateMutation,
  useGetOrdersQuery,
  useGetOrderActivityQuery,
  useLazyGetOrdersQuery,
  useGetOrdersIdQuery,
  useLazyGetOrdersIdQuery,
  useUpdateOrderMutation,
  useUpdateOrderDeliveryInfoMutation,
  useUpdateOrderDeliverySignatureMutation,
  useDeleteSignatureMutation,
  useCreateOrderMutation,
  useLazyGetOrderRoundTripsQuery,
  useLazyGetOrderRoundTripsBulkQuery,
  useUpdateOrderStatusBulkMutation,
  useUpdateCustomerOrderMutation,
  useGetProductNotesQuery,
  useLazyGetProductNotesQuery,
  useCreateProductNoteMutation,
  useDeleteProductNoteMutation,
  usePatchProductNoteMutation,
  useCreateTemplateMutation,
  useGetTemplatesQuery,
  useGetFrequentlyOrderedQuery,
  useLazyGetFrequentlyOrderedQuery,
  useUpdateOrderSupportUnitsMutation,
  useLazyGetOrderActivityQuery,
  useGetOrderFacetsQuery,
  useAddPhoneBookMutation,
  useUpdatePhoneBookMutation,
  useDeletePhoneBookMutation,
  useGetOrderCommentQuery,
  useAddOrderCommentMutation,
  useEditOrderCommentMutation,
  useDeleteOrderCommentMutation,
  useUncancelOrderMutation,
} = ordersApis;

export default ordersApis;
