import {
  Button,
  Grid,
  Grow,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import data from './data.json';
import { useEffect, useState } from 'react';
import { themes } from 'styles/theme/themes';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface quoteParams {
  id: number;
  quote: string;
  author: string;
  translation: string;
}
export function MotivationalQuote({ open, handleClose }) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  const [randomQuote, setRandomQuote] = useState<quoteParams>();

  useEffect(() => {
    if (open) {
      const random = data[Math.floor(Math.random() * data.length)];
      setRandomQuote(random);
    }
  }, [open, data]);

  return (
    <StyledModal open={open} onClose={handleClose}>
      <Grow in={open} timeout={{ enter: 800, exit: 400 }}>
        <Wrapper>
          <IconButton
            onClick={handleClose}
            sx={{
              marginLeft: 'auto',
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={`https://bamptee-cdn.s3.eu-west-3.amazonaws.com/assets/motivationQuotes/${randomQuote?.id}.svg`}
            alt="Bamptee"
          />
          <Grid container flexDirection="column">
            <Typography
              fontWeight={400}
              fontSize="20px"
              sx={{
                textAlign: 'center',
              }}
            >
              {locale === 'en' ? randomQuote?.quote : randomQuote?.translation}
            </Typography>
            <Typography
              fontWeight={300}
              fontSize="12px"
              color={themes?.default?.clientMarkerBg}
              sx={{
                textAlign: 'center',
              }}
            >
              {randomQuote?.author}
            </Typography>
          </Grid>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              width: '100%',
            }}
          >
            {t('motivationalQuote.title.button')}
          </Button>
        </Wrapper>
      </Grow>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  background: white;
  gap: 24px;
  height: 500px;
  width: 500px;
  box-shadow: -24px 24px 48px -12px #674daf57;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px;
`;
