import {
  Avatar,
  ButtonBase,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CaretLeft,
  CaretRight,
  Crosshair,
  DotsThree,
  RoadHorizon,
} from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { formatDeliveryHours } from 'common/helpers/place';
import { useTranslation } from 'react-i18next';
import {
  formatChipDate,
  StatusOrder,
} from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { useParams } from 'react-router-dom';
import { OrderStatus } from 'types';
import { useContext, useMemo } from 'react';
import { OrderDrawerContext_rt } from 'app/pages/AddRoundTrips';
import { OrderDrawerContext_orders } from 'app/pages/Orders';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { PopoverText } from 'hooks/Orders/useFormatOrderData';
import { PopoverEl } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import AssignRoundtrip from 'app/pages/Orders/components/AssignRoundtrip';
import { useToaster } from 'hooks/useToaster';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import { selectConfiguration } from 'common/store/organization/selectors';
import { getAddressLabel, getOrderStatusesToShow } from 'app/helpers/helpers';
import { useModal } from 'app/components/Dialog/hooks';
import { Icon } from 'app/components/Icon';
import { OutlinedChip } from 'app/components/OutlinedChip';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import OrderPriority from 'app/components/OrderPriority';
import StopIcon from 'app/components/StopIcon';
import If from 'app/components/If';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { ChangeOrderButton } from 'app/components/OrderDetail/components/ChangeOrderButton';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IOrderDetailsContext } from '../..';
import { useUpdateOrderStatusBulkMutation } from 'common/services/orderApi';
import { toQueryParams } from 'hooks/useQueryParams';
import { usePrintV2 } from 'hooks/RoundTrips/usePrint';
import { useCancelOrder } from 'hooks/Orders/useCancelOrder';

export const Header = ({ showMap }: { showMap: () => void }) => {
  const { order, refetch, canEdit, handleOpenRoundtripMap } =
    useManagedContext<IOrderDetailsContext>('orderDetails');
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const { token } = useParams();
  const canOnRoundtrips = usePermission('roundtrips');
  const contextValue_rt = useContext(OrderDrawerContext_rt);
  const contextValue_orders = useContext(OrderDrawerContext_orders);
  const toast = useToaster();
  const { openModal, closeModal } = useModal();

  const [updateOrders] = useUpdateOrderStatusBulkMutation();

  const { triggerPrint } = usePrintV2();

  const { openModal: openCancelModal } = useCancelOrder({
    onSuccess: (id) => {
      refetch(id);
    },
  });

  let switchOrder, disableNext, disablePrev;

  if (contextValue_rt) {
    ({ switchOrder, disableNext, disablePrev } = contextValue_rt);
  } else if (contextValue_orders) {
    ({ switchOrder, disableNext, disablePrev } = contextValue_orders);
  } else {
    switchOrder = () => {};
    disableNext = true;
    disablePrev = true;
  }

  function PrevOrder() {
    if (contextValue_rt) switchOrder('prev', order?._id, order?.roundtrip._id);
    else if (contextValue_orders) switchOrder('prev', order?._id);
    else return;
  }

  function NextOrder() {
    if (contextValue_rt) switchOrder('next', order?._id, order?.roundtrip._id);
    else if (contextValue_orders) switchOrder('next', order?._id);
    else return;
  }

  const changeStatus = async (status: string) => {
    try {
      if (status === OrderStatus.Canceled) {
        openCancelModal(order);
        return;
      }
      await updateOrders({
        orderIds: [order?._id],
        status,
      }).unwrap();
      refetch(order?._id);
      toast(3000, 'success', 'order_status_success');
    } catch (e: any) {
      toast(3000, 'error', 'order_status_error');
    }
  };
  const printDeliveryNote = () => {
    const query = toQueryParams({
      format: 'pdf',
      orderIds: order?._id,
    });
    triggerPrint({ uri: `orders/delivery-notes${query}` });
  };

  const configuration = useSelector(selectConfiguration);
  const orderStatusesList = getOrderStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );

  const statusActions: Array<{ action: string; element: JSX.Element }> = [];

  orderStatusesList.forEach((status) => {
    statusActions.push({
      action: status.status,
      element: (
        <ItemStatusComponent
          action={async () => {
            changeStatus && changeStatus(status.status);
          }}
          label={t(`orders.status.${status.label || status.status}`)}
          color={status.color}
        />
      ),
    });
  });

  const showMenu = useMemo(() => {
    return !!order?._id && canEdit;
  }, [canEdit, order?._id]);

  return (
    <Stack direction="row" gap="12px" alignItems="center">
      <Tooltip
        title={
          order?._deck?.location?.name
            ? `${order?._deck?.code || ''} - ${getAddressLabel(
                order?._deck?.location,
              )?.toUpperCase()}`
            : t('clients.orderDetails.noDeliveryAddress').toUpperCase()
        }
      >
        <IconButton sx={{ padding: 0 }} onClick={showMap}>
          <Icon icon={<Crosshair />} color={theme.primaryActiveColor} />
        </IconButton>
      </Tooltip>
      <Typography fontSize="1rem" color="black" fontWeight="700">
        {order?._customer?.code} -
      </Typography>
      <Typography fontSize="1rem" color="black" fontWeight="400">
        {order?._customer?.legalName}
      </Typography>
      <OutlinedChip
        content={formatDeliveryHours(order?._deck?.deliveryHours)}
      />
      <Tooltip title={order?._customer?._salesman?.fullName}>
        <Avatar
          src={order?._customer?._salesman?.pictureUrl}
          sx={{ width: '24px', height: '24px' }}
        />
      </Tooltip>
      <VerticalDivider />
      <OutlinedChip content={<OrderPriority priority={order?.priority} />} />
      <OutlinedChip
        content={
          <StopIcon
            type={order?.type || ''}
            tooltipMessage={t(
              `common.${order?.type}` || 'roundtrips.tooltip.collection',
            )}
            iconSize={12}
          />
        }
      />
      <If condition={!!order?.roundtrip?.code}>
        <ButtonBase onClick={handleOpenRoundtripMap}>
          <OutlinedChip
            content={
              <Stack direction="row" gap="2px" alignItems="center">
                <Icon icon={<RoadHorizon />} color={theme.primary} size={12} />
                <Typography fontSize="0.75rem" color={theme.nobel}>
                  {order?.roundtrip?.code}
                </Typography>
              </Stack>
            }
          />
        </ButtonBase>
      </If>
      <VerticalDivider />
      <StatusOrder
        status={order?.status}
        context={token ? 'ordoria' : 'bianta'}
        deliveredAt={
          [OrderStatus.Canceled, OrderStatus.Delivered]?.includes(order?.status)
            ? formatChipDate(order?.updatedStatusAt)
            : ''
        }
        deliveryDistance={order?.deliveryInfo?.distance}
      />

      <Stack direction="row" gap="20px" alignItems="center" marginLeft="auto">
        <If condition={!!showMenu}>
          <NestedMenuComponent
            leftPosition={true}
            id={order?._id}
            transformOriginHorizontal="left"
            anchorOriginHorizontal="right"
            iconAction={
              <Icon icon={<DotsThree />} color={theme.iconColor} size={20} />
            }
            actions={[
              {
                action: 'assign_roundtrip',
                nested: false,
                actions: [{ elements: null, action: 'assign_roundtrip' }],
                element: canOnRoundtrips(Permission.EDIT) ? (
                  <PopoverText>
                    <PopoverEl>
                      <div
                        style={{
                          marginLeft: theme.popoverLabelPadding,
                          paddingTop: '5px',
                        }}
                        onClick={() => {
                          openModal({
                            title: t('assign_order_code_to', {
                              code: order.code || '',
                            }),
                            content: (
                              <AssignRoundtrip
                                orderIds={[order?._id]}
                                selectedOrders={[
                                  {
                                    id: order?._id,
                                    placeId: order._deck?._id,
                                    type: order.type,
                                  },
                                ]}
                                atAssign={() => {
                                  closeModal();
                                  toast(
                                    3000,
                                    'success',
                                    'order.roundtrip.assign',
                                  );
                                }}
                              />
                            ),
                          });
                        }}
                      >
                        {t('orders.add_to_roundtrip')}
                      </div>
                    </PopoverEl>
                  </PopoverText>
                ) : null,
              },
              {
                action: 'Change_Status',
                nested: true,
                actions: statusActions,
                element: (
                  <PopoverText>
                    <PopoverEl>
                      <div
                        style={{
                          marginLeft: theme.popoverLabelPadding,
                          paddingTop: '5px',
                        }}
                      >
                        {t('common.change_status')}
                      </div>
                    </PopoverEl>
                  </PopoverText>
                ),
              },
              {
                action: 'Print_Delivery',
                onClick: printDeliveryNote,
                element: (
                  <PopoverText>
                    <PopoverEl>
                      <div
                        style={{
                          marginLeft: theme.popoverLabelPadding,
                          paddingTop: '5px',
                        }}
                      >
                        {t('print.delivery-note-button')}
                      </div>
                    </PopoverEl>
                  </PopoverText>
                ),
              },
            ]}
          />
        </If>

        <If condition={!!order?._id}>
          <Stack direction="row" alignItems="center" gap="12px">
            <ChangeOrderButton
              icon={<CaretLeft />}
              action={PrevOrder}
              disabled={disablePrev}
            />
            <ChangeOrderButton
              icon={<CaretRight />}
              action={NextOrder}
              disabled={disableNext}
            />
          </Stack>
        </If>
      </Stack>
    </Stack>
  );
};
