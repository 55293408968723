import React, { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { Divider, IconButton, Stack } from '@mui/material';
import styled from 'styled-components';
import { styled as MUIStyled } from '@mui/material/styles';
import HiddenTabsMenu from './HiddenTabsMenu';
import ManagedTab from './ManagedTab';
import { Icon } from '../Icon';
import { PlusCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { pagePreferencesActions } from '../../../common/store/pagePreferences';
import { selectTheme } from 'styles/theme/slice/selectors';

interface Props {
  suppressPersist?: boolean;
}

const TabsContainer = MUIStyled('div')(() => ({
  display: 'flex',
  gap: '6px',
  height: '100%',
  alignItems: 'center',
  // borderBottom: `1px solid ${theme.palette.divider}`,
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ManagedTabs = ({ suppressPersist = false }: Props) => {
  const tabsContext = useManagedContext('tabs');

  const theme = useSelector(selectTheme);

  const { t } = useTranslation();
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedTabs = reorder(
      tabsContext.visibleTabs,
      source.index,
      destination.index,
    );
    tabsContext.updateDataWithFunction((prev) => {
      prev.visibleTabs = reorderedTabs;
      prev.isDragging = false;
    });
  };
  // current route
  const currentRoute = useLocation().pathname.split('/').join('-');
  const dispatch = useDispatch();
  useEffect(() => {
    if (!tabsContext.visibleTabs.length && !suppressPersist) return;
    const handleBeforeUnload = () => {
      const currentOrder = tabsContext.visibleTabs.concat(
        tabsContext.hiddenTabs,
      );
      dispatch(
        pagePreferencesActions.setTabsOrder({
          key: currentRoute,
          order: currentOrder.map((t) => t.value),
        }),
      );
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handleBeforeUnload();
    };
  }, [currentRoute, tabsContext.visibleTabs, tabsContext.hiddenTabs]);
  return (
    <Stack direction="row" alignItems="center" gap={1} maxHeight="40px">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-tabs" direction="horizontal">
          {(droppableProvided) => (
            <TabsContainer
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {tabsContext.visibleTabs.map((tab, index) => (
                <>
                  <Draggable
                    key={tab.value}
                    draggableId={tab.value}
                    index={index}
                    onDragStart={() => {
                      tabsContext.updateDataWithFunction((prev) => {
                        prev.isDragging = true;
                      });
                    }}
                  >
                    {(draggableProvided) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <ManagedTab tab={tab} />
                      </div>
                    )}
                  </Draggable>
                  {index < tabsContext.visibleTabs.length - 1 && (
                    <Divider orientation="vertical" sx={{ height: 12 }} />
                  )}
                </>
              ))}
              {droppableProvided.placeholder}
            </TabsContainer>
          )}
        </Droppable>
      </DragDropContext>
      {!!tabsContext.hiddenTabs.length && <HiddenTabsMenu />}
      {tabsContext.canEdit &&
        tabsContext.onAddClick &&
        tabsContext.editable && (
          <IconButton
            onClick={() => {
              tabsContext?.onAddIconOptions?.onClick
                ? tabsContext?.onAddIconOptions?.onClick()
                : tabsContext.updateDataWithFunction((prev) => {
                    prev.hiddenTabs.push(prev.visibleTabs.pop());
                    prev.visibleTabs.push({ label: '', value: '' });
                    prev.currentTabEdit = '';
                  });
            }}
            sx={{ height: '28px', width: '28px', padding: '0px' }}
          >
            <Icon
              icon={<PlusCircle />}
              tooltip={
                tabsContext?.onAddIconOptions?.tooltip
                  ? tabsContext?.onAddIconOptions?.tooltip
                  : t('tooltip.add_section')
              }
              color={theme.primaryActiveColor}
            />
          </IconButton>
        )}
    </Stack>
  );
};

export const MenuButtonContainer = styled.div<{
  menuBtnVisible?: any;
  canEdit: boolean;
}>`
  opacity: ${(props) => (props.menuBtnVisible ? 1 : 0)};
  background: white;
  border-radius: 200px;
  position: absolute;
  right: 5px;
  transition-duration: 0.2s;
  visibility:  'visible')};
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 6px;

  &:hover {
    ${MenuButtonContainer} {
      opacity: 1;
    }
  }
`;

export const MenuItemsText = styled.span`
  color: var(--Text-color-Primary, #2a2d33);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 1.16188rem */
  letter-spacing: 0.01063rem;
  //color: var(--App-Colors-Primary, #2a59ff);
`;
export default ManagedTabs;
