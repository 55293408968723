import { useCallback, useEffect, useMemo, useRef } from 'react';
import { IGridContext, IGridProviderProps, IStatsConfig } from './types';
import ManagedContext from '../../../common/UtilityComponents/ManagedContext/ManagedContext';
import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';
import dayjs from 'dayjs';
import { useExportCSV } from '../../../hooks/useExportCSV';
import { getColumnDefs } from './helpers';
import { getFilterCount } from '../../helpers/helpers';
import debounce from 'lodash/debounce';
import TabsContext from '../ManagedTabs/TabsContext';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import {
  selectColumnsPreferences,
  selectFiltersPreferences,
} from '../../../common/store/pagePreferences/selectors';

function GridContext<T extends object, U extends object = T>({
  children,
  data,
  title,
  tabs,
  initialTab,
  onTabChange,
  statsConfig,
  leftPins,
  rightPins,
  mapComponent,
  listComponent,
  cardComponent,
  canEdit,
  canExport,
  contextName,
  defaultSort,
  customColumnConfig,
  rowHeight,
}: IGridProviderProps<T, U>) {
  const memoData = useMemo(() => {
    if (!data) return [];
    return data;
  }, [data]) as U[];

  const currentRoute = useLocation().pathname.split('/').join('-');
  const persistedFilters = useSelector(selectFiltersPreferences)[currentRoute];

  // @ts-ignore
  const { persistStatus, ...filtersWithoutStatus } = persistedFilters ?? {};
  return (
    <ManagedContext
      contextName={contextName || 'grid'}
      defaultValue={
        {
          rowData: memoData,
          responseData: [],
          viewMode: 'grid',
          columnDefs: [],
          theme: 'light',
          loading: true,
          mapComponent,
          listComponent,
          cardComponent,
          hasMultipleViews:
            !!mapComponent || !!listComponent || !!cardComponent || false,
          queryParams: {
            page: 1,
            rowsPerPage: 30,
            order: '',
            orderBy: defaultSort ?? '',
            status:
              persistStatus !== 'undefined' && persistStatus
                ? persistStatus
                : '',
            filters: filtersWithoutStatus ?? {},
            filterInit: filtersWithoutStatus ?? {},
            search: '',
          },
          title,
          selectedRows: [],
          selectedRow: null,
          canEdit: canEdit ?? false,
          canExport: canExport ?? false,
          openDrawer: false,
          menuIsOpen: false,
          menuOptions: undefined,
          withStatsCards: !!statsConfig,
          leftPins: leftPins ?? 0,
          rightPins: rightPins ?? 0,
          customColumnConfig,
          rowHeight: rowHeight,
          totalRows: 0,
          filtersCount: 0,
          disableToolBar: false,
          api: {
            moveColumnByField: (_srcField: string, _destField: string) => {},
            setColumnsVisible: (_field: string[], _visible: boolean) => {},
            exportRows: (_url, _module) => {},
            openToolbarMenu: (_event: React.MouseEvent<HTMLElement>) => {},
            disableToolBar: (_extraCondition: boolean) => false,
            openNoDataMenu: (_event: React.MouseEvent<HTMLElement>) => {},
            clearSelection: () => {},
            getCurrentColumns: () => [],
            pinColumn: (_field: string, _pinned: string) => {},
          },
          extraData: {},
        } as IGridContext<T, U>
      }
    >
      <DataSetter<T, U> />
      <ManagedContext
        contextName="statsConfig"
        defaultValue={
          statsConfig
            ? {
                title: statsConfig.title,
                facet: null,
                statuses: statsConfig.statuses,
                colors: statsConfig.colors,
                statusesMetrics: {},
                calculateStatusMetrics: statsConfig.calculateStatusMetrics,
                calculateGrandTotal: statsConfig.calculateGrandTotal,
                grandTotal: 0,
              }
            : ({} as IStatsConfig)
        }
      >
        <StatsConfigSetter />
        <TabsContext
          tabs={tabs ?? []}
          initialTab={initialTab ?? tabs?.[0]?.value ?? ''}
          onTabChange={onTabChange}
          canEdit={canEdit}
        >
          {children}
        </TabsContext>
      </ManagedContext>{' '}
    </ManagedContext>
  );
}

function DataSetter<T extends object, U extends object = T>() {
  const context = useManagedContext<IGridContext<T, U>>('grid');
  const {
    rowData: data,
    leftPins,
    rightPins,
    customColumnConfig,
    columnDefs,
    selectedRows,
    loading,
  } = context;

  const currentRoute = useLocation().pathname.split('/').join('-');
  const columnsPreferences = useSelector(selectColumnsPreferences)[
    currentRoute
  ];
  const columnsOrder = columnsPreferences?.map((c) => c.split('#')[0]);
  const pinnigs = columnsPreferences?.map((c) => c.split('#')?.[1]);
  const visibilties = columnsPreferences?.map((c) => c.split('#')?.[2]);

  useEffect(() => {
    if (!data || data.length === 0 || loading) return;

    context.updateDataWithFunction((prev) => {
      if (columnDefs.length) return;
      prev.columnDefs = getColumnDefs<U>(
        data,
        leftPins,
        rightPins,
        customColumnConfig,
      )
        .sort((a, b) => {
          return (
            columnsOrder?.indexOf(a.field) - columnsOrder?.indexOf(b.field)
          );
        })
        .map((colDef) => {
          const pinned = pinnigs?.[columnsOrder?.indexOf(colDef.field)];
          if (pinned !== 'undefined' && pinned !== 'false') {
            colDef.pinned = pinned === 'true' ? true : pinned;
          } else {
            colDef.pinned = undefined;
          }
          if (visibilties?.[columnsOrder?.indexOf(colDef.field)] === 'hide') {
            colDef.hide = true;
          }
          return colDef;
        });
    });
  }, [data]);
  const { triggerExport } = useExportCSV();
  const exportRows = useCallback(
    (url: string, fileName: string) => {
      triggerExport(
        `${url}&limit=25000&page=1&idsFilters=${
          selectedRows?.length ? selectedRows.join(',') : ''
        }`,
        `${fileName}_${dayjs().format('DD/MM/YYYY')}`,
      );
    },
    [triggerExport, selectedRows.length],
  );
  useEffect(() => {
    context.updateDataWithFunction((prev) => {
      prev.api.exportRows = exportRows;
    });
  }, [triggerExport, selectedRows.length]);

  const debouncedGetFilterCount = useCallback(
    debounce((filters, filterInit, callback) => {
      const count = getFilterCount(filters, filterInit);
      callback(count);
    }, 500),
    [context.queryParams],
  );

  useEffect(() => {
    if (context.queryParams.filters) {
      debouncedGetFilterCount(
        context.queryParams.filters,
        context.queryParams.filterInit,
        (filtersCount) => {
          context.updateData('filtersCount', filtersCount);
        },
      );
    }
  }, [context.queryParams.filters, context.queryParams.filterInit]);
  useEffect(() => {
    const disableToolBar =
      context.totalRows === 0 &&
      !context.queryParams.search &&
      context.filtersCount === 0;
    context.updateData('disableToolBar', disableToolBar);
  }, [context.totalRows, context.queryParams.search, context.filtersCount]);
  return null;
}

function StatsConfigSetter() {
  const statsConfigContext = useManagedContext<IStatsConfig>('statsConfig');
  const isFirstRender = useRef<boolean>(true);
  const { facet } = statsConfigContext;
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!facet) return;

    statsConfigContext.updateDataWithFunction((prev) => {
      prev.grandTotal = statsConfigContext.calculateGrandTotal(facet);
    });
  }, [facet]);
  return null;
}
export default GridContext;
