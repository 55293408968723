import { Grid, MenuItem, TextField } from '@mui/material';
import { FieldComponent } from 'app/components/FieldComponent';
import FormSelectField from 'app/components/Form/SelectField';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { StatusCustomer } from 'app/components/StatusCustomer';
import { useGetCustomerTypesQuery } from 'common/services/customerApi';
import { CustomerStatus } from 'common/types/Customer';
import { useCheckCode } from 'hooks/Customers/useCheckCode';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import { useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomerFormcontext } from '../../context';

export const ClientInformation = ({ control, errors }) => {
  const { t } = useTranslation();
  const { customer, canEdit } = useContext(CustomerFormcontext);
  const { data: clientGroups } = useGetCustomerTypesQuery({});
  const { validate } = useCheckCode();
  const { formatError } = useErrorFormatter();

  const statuses = useMemo(() => {
    return Object.values(CustomerStatus).map((status) => {
      return {
        label: t(`customer.status.${status}`),
        value: status,
      };
    });
  }, [t]);
  const groups = useMemo(() => {
    return (
      clientGroups?.map((group) => {
        return {
          label: group.label,
          value: group._id,
        };
      }) || []
    );
  }, [clientGroups]);
  return (
    <FormSection>
      <SectionTitle>{t('client_information')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item md={4} sm={4} xs={12}>
          <Controller
            name="code"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('common.code')} ${t('is_required')}`,
              },
              validate(value) {
                return validate({
                  code: value?.toString() || '',
                  id: customer?._id,
                });
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                type="text"
                label={`${t('common.code')}`}
                variant="outlined"
                error={!!errors?.code}
                helperText={formatError(errors?.code, t('common.code'))}
                fullWidth
                disabled={!canEdit}
                onChange={(value) => field.onChange(value)}
                required
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <FormTextField
            name="publicName"
            label="customer.publicName"
            control={control}
            disabled={!canEdit}
            rules={{ required: true }}
            required
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <FieldComponent
            control={control}
            name="status"
            label={t('client.statusTitle')}
            select
            InputProps={{
              disabled: !canEdit,
              size: 'small',
            }}
          >
            {statuses?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <StatusCustomer status={option.value} />
              </MenuItem>
            ))}
          </FieldComponent>
        </Grid>
      </Grid>
      <Grid container spacing="12px">
        <Grid item md={6} sm={6} xs={12}>
          <FormSelectField
            name="_customerType"
            label="group"
            control={control}
            data={groups}
            checkboxes={false}
            multiple={false}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="category"
            label="common.category"
            control={control}
            disabled={true}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing="12px">
        <Grid item xs={12}>
          <FormSelectField
            name="tags"
            label="info-client-Tags"
            control={control}
            data={[]}
            checkboxes={false}
            multiple={false}
            disabled={!canEdit}
          />
        </Grid>
      </Grid> */}
    </FormSection>
  );
};
