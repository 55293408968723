import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePatchWarehouseMutation } from 'common/services/organizationApi';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useManagedContext } from '../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IWarehouseLocation } from '../../../../../../../common/types/Warehouses';
import { IGridContext } from '../../../../../../components/ManagedGrid/types';
import ColorPicker from './ColorPicker';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LocationAutoComplete } from './LocationAutoComplete';
import PhoneInput from 'react-phone-input-2';
import _ from 'lodash';

function WarehousesDrawer() {
  const gridContext = useManagedContext<IGridContext>('grid');
  const { dayjs } = window;
  const { t } = useTranslation();
  const [patchWarehouse] = usePatchWarehouseMutation();
  const [isSwitchOn, setSwitchOn] = useState<boolean>(false);

  const schema = z.object({
    name: z
      .string({ message: 'this field is required' })
      .min(1, { message: 'this field is required' })
      .refine(
        (val) => {
          const nameExist = gridContext.responseData.some(
            (item) => item.name === val,
          );
          return !nameExist;
        },
        { message: 'Name already exist' },
      ),
    initials: z
      .string({ message: 'this field is required' })
      .min(1)
      .max(3)
      .refine(
        (val) => {
          const initialsExist = gridContext.responseData.some(
            (item) => item.shortName === val,
          );
          return !initialsExist;
        },
        { message: 'Initial already exist' },
      ),
    color: z.string({ message: 'this field is required' }).min(1),
    location: z.any().refine((val) => val !== undefined && val !== null, {
      message: 'this field is required',
    }),
    capacity: z
      .number()
      .positive({ message: 'Capacity must be a positive number' })
      .optional(),
    manager: z.string().optional(),
    phone: z.string().optional(),
  });
  useEffect(() => {
    setSwitchOn(gridContext.selectedRow?.status === 'active');
  }, [gridContext.selectedRow]);

  const [location, setLocation] = useState<IWarehouseLocation | undefined>(
    gridContext.selectedRow?.location,
  );
  const [openingTime, setOpeningTime] = useState<Dayjs | null>(
    gridContext.selectedRow?.operatingHours?.openingTime
      ? dayjs(gridContext.selectedRow.operatingHours.openingTime)
      : dayjs().startOf('day'),
  );
  const [closingTime, setClosingTime] = useState<Dayjs | null>(
    gridContext.selectedRow?.operatingHours?.closingTime
      ? dayjs(gridContext.selectedRow.operatingHours.closingTime)
      : dayjs().startOf('day'),
  );

  const methods = useForm({
    defaultValues: {
      name: gridContext.selectedRow?.name,
      initials: gridContext.selectedRow?.shortName,
      color: gridContext.selectedRow?.color,
      location,
      capacity: gridContext.selectedRow?.capacity,
      manager: gridContext.selectedRow?.manager,
      phone: gridContext.selectedRow?.phone,
    },

    resolver: zodResolver(schema),
  });

  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = methods;

  const handleSwitchChange = () => {
    setSwitchOn((prevState) => !prevState);
  };

  const name = watch('name');
  const initials = watch('initials');
  const color = watch('color');
  // const location = watch('location');
  const capacity = watch('capacity');
  const manager = watch('manager');
  const contact = watch('phone');

  console.log('initials and color', initials, color);

  async function handlePatchWarehouse() {
    const newState = isSwitchOn ? 'active' : 'inactive';
    const formatClosingTime = closingTime?.format('hh:mm');
    const formatOpeningTime = openingTime?.format('hh:mm');

    try {
      await patchWarehouse({
        deckId: gridContext.selectedRow?._id,
        name: name,
        location,
        capacity: capacity,
        manager: manager,
        phone: contact,
        status: newState,
        shortName: initials,
        color: color,
        openingHours: [
          {
            openingTime: formatOpeningTime,
            closingTime: formatClosingTime,
          },
        ],
      });
      gridContext.updateDataWithFunction((prev) => {
        prev.openDrawer = false;
        prev.selectedRow = null;
      });
    } catch (e) {
      console.log('error on update gridContext.selectedRow' + e);
    }
  }

  return (
    <TwoViewModal
      onClose={() => {
        gridContext.updateDataWithFunction((prev) => {
          prev.openDrawer = false;
          prev.selectedRow = null;
        });
      }}
      width="525px"
      onOpen={gridContext.openDrawer}
      title={
        gridContext.selectedRow
          ? gridContext.canEdit
            ? t('edit_warehouse')
            : t('view_warehouse')
          : t('add_warehouse')
      }
    >
      <Wrapper>
        <TextFieldWrapper>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  onChange={(e) => {
                    field.onChange(e);
                    if (errors.name) {
                      trigger('name');
                    }
                  }}
                  defaultValue={
                    gridContext.selectedRow ? gridContext.selectedRow.name : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: '80%',
                  }}
                  disabled={!gridContext.canEdit}
                  onBlur={() => trigger('name')}
                  label="Warehouse Name"
                  error={!!errors.name}
                  helperText={!!errors.name ? String(errors.name.message) : ''}
                  style={{ marginBottom: '23px !important' }}
                />
              )}
            />
            <Controller
              name="initials"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  onChange={(e) => {
                    if (e.target.value.length <= 3) {
                      field.onChange(e);
                    }
                    if (errors.initials) {
                      trigger('initials');
                    }
                  }}
                  defaultValue={
                    gridContext.selectedRow?.shortName
                      ? gridContext.selectedRow.shortName
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: '20%',
                  }}
                  disabled={!gridContext.canEdit}
                  onBlur={() => trigger('initials')}
                  label="Initials"
                  error={!!errors.initials}
                  helperText={
                    !!errors.initials ? String(errors.initials.message) : ''
                  }
                  style={{ marginBottom: '23px !important' }}
                />
              )}
            />
          </Box>
          <ColorPicker
            name="color"
            control={control}
            label={t('common.color')}
            colors={[
              '#EEEEEE',
              '#9FA8DA',
              '#90CAF9',
              '#80CBC4',
              '#C5E1A5',
              '#FFF59D',
              '#FFAB91',
              '#EF9A9A',
              '#F48FB1',
              '#CE93D8',
              '#B39DDB',
              '#BDBDBD',
            ]}
          />
          <LocationAutoComplete
            label={t('storage.location')}
            defaultValue={gridContext.selectedRow?.location}
            size="small"
            control={control}
            onBlur={() => trigger('location')}
            onSelect={(loc) => {
              setLocation(loc);
            }}
            error={!!errors.location}
            helperText={
              !!errors.location ? String(errors.location.message) : ''
            }
            disabled={!gridContext.canEdit}
          />

          <Controller
            name="manager"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                onChange={(e) => {
                  field.onChange(e);
                  if (errors.manager) {
                    trigger('manager');
                  }
                }}
                defaultValue={
                  gridContext.selectedRow?.shortName
                    ? gridContext.selectedRow.shortName
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  flex: 1,
                }}
                disabled={!gridContext.canEdit}
                onBlur={() => trigger('manager')}
                label="Manager"
                error={!!errors.manager}
                helperText={
                  !!errors.manager ? String(errors.manager.message) : ''
                }
                style={{ width: '100%', marginBottom: '23px !important' }}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                country="fr"
                onChange={(value) => {
                  field.onChange(value);
                  if (errors.phone) {
                    trigger('phone');
                  }
                }}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  marginBottom: '23px !important',
                  borderRadius: '4px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                }}
                containerStyle={{
                  width: '100%',
                }}
                buttonStyle={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
                dropdownStyle={{
                  boxShadow: 'none',
                }}
                disabled={!gridContext.canEdit}
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                specialLabel="Contact"
                isValid={!errors.phone}
                // defaultErrorMessage={errors.phone?.message}
                // helperText={!!errors.phone ? String(errors.phone.message) : ''}
              />
            )}
          />
        </TextFieldWrapper>
        <div
          className="TextFieldLine"
          style={{
            display: 'flex',
            margin: '0px 20px 0px 20px',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Opening Time"
              value={openingTime}
              onChange={(newValue) => setOpeningTime(newValue)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{
                    width: '50%',
                    mr: '19px',
                    height: '37px',
                  }}
                  defaultValue={openingTime}
                  size="small"
                />
              )}
              disabled={!gridContext.canEdit}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Closing Time"
              value={closingTime}
              onChange={(newValue) => setClosingTime(newValue)}
              inputFormat="HH:mm"
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{
                    width: '50%',
                    height: '37px',
                  }}
                  defaultValue={closingTime}
                  size="small"
                />
              )}
              disabled={!gridContext.canEdit}
            />
          </LocalizationProvider>
        </div>
        <div
          className="TextFieldLine"
          style={{
            display: 'flex',
            margin: '0px 20px 0px 20px',
          }}
        >
          <Controller
            name="capacity"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value)) {
                    field.onChange(value);
                  }
                  if (errors.capacity) {
                    trigger('capacity');
                  }
                }}
                id="filled-number"
                type="number"
                defaultValue={
                  gridContext.selectedRow?.shortName
                    ? gridContext.selectedRow.shortName
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: 'rgba(0, 0, 0, 0.78)',
                      }}
                    >
                      m<sup>3</sup>
                    </Typography>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  flex: 1,
                }}
                disabled={!gridContext.canEdit}
                onBlur={() => trigger('capacity')}
                label="Capacity"
                error={!!errors.capacity}
                helperText={
                  !!errors.capacity ? String(errors.capacity.message) : ''
                }
                style={{ width: '100%', marginBottom: '23px !important' }}
              />
            )}
          />
        </div>
        <FormGroup
          sx={{
            ml: '24px',
          }}
        >
          {gridContext.selectedRow ? (
            <Typography component="div">
              <Switch
                disabled={!gridContext.canEdit}
                checked={isSwitchOn}
                onChange={handleSwitchChange}
              />
              {isSwitchOn ? 'Active' : 'Non active'}
            </Typography>
          ) : (
            <FormControlLabel
              control={
                <Switch disabled={!gridContext.canEdit} checked={false} />
              }
              label="Non active"
            />
          )}
        </FormGroup>
      </Wrapper>

      <FooterWrapper>
        <Button
          variant="outlined"
          sx={{ mr: '12px' }}
          onClick={() => {
            gridContext.updateDataWithFunction((prev) => {
              prev.openDrawer = false;
              prev.selectedRow = null;
            });
          }}
        >
          {t('common.buttons.cancel')}
        </Button>

        <Button
          variant="contained"
          sx={{ mr: '20px' }}
          onClick={gridContext.selectedRow ? handlePatchWarehouse : undefined}
          disabled={
            !gridContext.canEdit ||
            Object.keys(errors).length > 0 ||
            !name ||
            !initials ||
            !color ||
            !location ||
            (gridContext.selectedRow &&
              name === gridContext.selectedRow?.name &&
              initials === gridContext.selectedRow?.shortName &&
              color === gridContext.selectedRow?.color &&
              _.isEqual(location, gridContext.selectedRow?.location) &&
              capacity === gridContext.selectedRow?.capacity &&
              manager === gridContext.selectedRow?.manager &&
              contact === gridContext.selectedRow?.phone &&
              isSwitchOn === (gridContext.selectedRow?.status === 'active'))
          }
        >
          {gridContext.selectedRow ? t('common.buttons.save') : t('common.add')}
        </Button>
      </FooterWrapper>
    </TwoViewModal>
  );
}
export default WarehousesDrawer;

const TextFieldWrapper = styled.div`
  margin: 40px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Wrapper = styled.div`
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: hidden;
`;

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  background: #fff;
`;

// const ButtonWrapper = styled.div`
//   margin-left: auto;
// `;
