import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { GetRowIdParams, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { IGridContext } from './types';
import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';
import './index.css';
import HeaderComponent from './components/HeaderComponent';
import { useTheme } from 'common/hooks/useTheme';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

function DataGrid<T, U>() {
  const theme = useTheme();
  const gridWrapperRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<AgGridReact<U[]>>(null);
  const gridContext = useManagedContext<IGridContext<T, U>>('grid');
  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      suppressHeaderFilterButton: true,
      width: 150,
      cellStyle: {
        border: 'none',
        // borderRight: '1px solid #000',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontFamily: 'Roboto, sans-serif',
      },
    };
  }, []);
  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow' as 'multiRow',
      headerCheckbox: true,
    };
  }, []);

  const moveColumnByField = (srcField, destField) => {
    const cols: any = gridRef.current!.api.getAllGridColumns();
    const srcIndex = cols.findIndex(
      (column) => column?.colDef.field === srcField,
    );

    const destIndex = cols.findIndex(
      (column) => column?.colDef.field === destField,
    );
    gridRef.current!.api.moveColumnByIndex(srcIndex!, destIndex!);
    gridContext.updateDataWithFunction((prev) => {
      prev.columnDefs = gridRef
        .current!.api.getAllGridColumns()
        .map((col: any) => {
          return col.colDef;
        })
        .slice(1);
    });
  };
  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      prev.api = {
        ...prev.api,
        moveColumnByField,
        setColumnsVisible: (fields: string[], visible: boolean) => {
          const filteredFields = fields.filter((field) => {
            const column = gridRef.current!.api.getColumnDef(field);
            return !column?.lockVisible;
          });
          gridRef.current!.api.setColumnsVisible(filteredFields, visible);
          gridContext.updateDataWithFunction((prev) => {
            prev.columnDefs = gridRef.current!.api.getColumnDefs()!;
          });
        },
        disableToolBar: (extraCondition) => {
          return (
            gridContext.totalRows === 0 &&
            !gridContext.queryParams.search &&
            gridContext.filtersCount === 0 &&
            extraCondition
          );
        },
        clearSelection: () => {
          gridRef.current!.api.deselectAll();
        },
        getCurrentColumns: () => {
          if (!gridRef.current) return gridContext.columnDefs;
          return (
            gridRef
              .current!.api.getAllGridColumns()
              // @ts-ignore
              .map((col) => col.colDef)
              .slice(1)
          );
        },
        pinColumn: (
          field: string,
          pinned: 'left' | 'right' | boolean | null | undefined,
        ) => {
          if (!gridRef.current) return;
          gridRef.current!.api.setColumnsPinned([field], pinned);

          gridContext.updateDataWithFunction((prev) => {
            prev.columnDefs = gridRef
              .current!.api.getColumnDefs()!
              .sort((a, b) => {
                // @ts-ignore
                if (a.pinned === 'left' && b.pinned !== 'left') return -1;
                // @ts-ignore
                if (a.pinned !== 'left' && b.pinned === 'left') return 1;
                // @ts-ignore
                if (a.pinned === 'right' && b.pinned !== 'right') return 1;
                // @ts-ignore
                if (a.pinned !== 'right' && b.pinned === 'right') return -1;

                return 0;
              });
          });
        },
      };
    });
  }, []);

  const getRowId = useCallback(
    (params: GetRowIdParams) => String(params.data.id),
    [],
  );
  const components = useMemo<{
    [p: string]: any;
  }>(() => {
    return {
      agColumnHeader: HeaderComponent,
    };
  }, []);

  const themeColors = useMemo(() => {
    return {
      'active-color': theme.primaryActiveColor,
    };
  }, [theme.primaryActiveColor]);

  useEffect(() => {
    const gridElement = gridWrapperRef.current;
    if (!gridElement) return;

    const colorEntries = Object.entries(themeColors);

    colorEntries.forEach(([key, value]) => {
      gridElement.style.setProperty(`--ag-${key}`, value);
    });

    return () => {
      colorEntries.forEach(([key]) => {
        gridElement.style.removeProperty(`--ag-${key}`);
      });
    };
  }, [themeColors]);

  return (
    <div
      ref={gridWrapperRef}
      className={`ag-theme-quartz${
        gridContext.theme === 'dark' ? '-' + gridContext.theme : ''
      }`}
      style={{ height: '100%' }}
    >
      <AgGridReact
        rowData={gridContext.rowData}
        ref={gridRef}
        columnDefs={gridContext.columnDefs}
        domLayout="normal"
        loading={gridContext.loading}
        getRowId={getRowId}
        scrollbarWidth={20}
        suppressNoRowsOverlay={true}
        alwaysShowHorizontalScroll={false}
        components={components}
        alwaysShowVerticalScroll={false}
        defaultColDef={defaultColDef}
        onColumnMoved={(e) => {
          const colDef = e.column?.getColDef();
          if (!colDef) return;
          colDef.pinned =
            e.toIndex === 0 ? 'left' : e.toIndex === 1 ? 'right' : undefined;
          gridContext.updateDataWithFunction((prev) => {
            prev.columnDefs = gridRef.current!.api.getColumnDefs()!;
          });
        }}
        onColumnResized={(e) => {
          const resizedColumn = e.column;
          if (resizedColumn) {
            const colDef = resizedColumn.getColDef();
            if (e.finished) {
              colDef.cellStyle = {
                ...colDef.cellStyle,
                backgroundColor: 'transparent',
                borderRight: 'none',
                borderLeft: 'none',
              };
              colDef.headerClass = '';
            } else {
              const isPinnedRight = colDef.pinned === 'right';
              colDef.cellStyle = {
                ...colDef.cellStyle,
                borderRight: isPinnedRight ? 'none' : '2px solid #949494',
                borderLeft: isPinnedRight ? '2px solid #949494' : 'none',
                backgroundColor: '#FAFAFA',
              };
              colDef.headerClass = isPinnedRight
                ? 'ag-header-resizing-pinned-right'
                : 'ag-header-resizing';
            }
            e.api.refreshCells({ force: true, columns: [resizedColumn] });
            e.api.refreshHeader();
          }
        }}
        rowSelection={rowSelection}
        selectionColumnDef={{
          pinned: 'left',
          lockPinned: true,
        }}
        onSortChanged={(e: any) => {
          gridContext.updateDataWithFunction((prev) => {
            if (!e.columns?.[0]) return;
            prev.queryParams.order =
              e.columns[e.columns.length - 1].sort === 'asc' ? '-' : '';
            prev.queryParams.orderBy =
              e.columns[
                e.columns.length - 1
              ].getUserProvidedColDef()?.sortField;
          });
        }}
        onSelectionChanged={(e) => {
          gridContext.updateData(
            'selectedRows',
            e.api.getSelectedRows().map((r) => r.id),
          );
        }}
        rowHeight={gridContext.rowHeight}
      />
    </div>
  );
}
export default DataGrid;
