import { Controller, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { IconButton, Stack } from '@mui/material';
import AvailableInternalCodeAutoComplete from './AutoCompleteComponents/AvailableInternalCodeAutoComplete';
import DriverAutoComplete from './AutoCompleteComponents/DriverAutoComplete';
import VehiclesAutoComplete from './AutoCompleteComponents/VehiclesAutoComplete';
import {
  useAddRoundtripMappingMutation,
  useDeleteRoundtripMappingMutation,
} from '../../../../../common/services/roundtripMappings';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IRoundtripInfo, IRoundtripMappingsContext } from '../index';
import { MinusCircle } from '@phosphor-icons/react';
import { Icon } from '../../../../components/Icon';
import { themes } from '../../../../../styles/theme/themes';

function RoundtripFormRow({
  warehouseId,
  roundtrip,
  index,
}: {
  warehouseId: string;
  roundtrip: IRoundtripInfo;
  index: number;
}) {
  const roundtripsMapingsContext = useManagedContext<IRoundtripMappingsContext>(
    'RoundtripMappingsContext',
  );

  const [deleteMapping] = useDeleteRoundtripMappingMutation();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      area: {
        label: 'paris',
        value: '66cdf11d5ed5d2ade92f9289',
      },
      roundtripCode: {
        label: roundtrip.roundtripCode || '',
        value: roundtrip.roundtripCode || '',
      },
      driver: roundtripsMapingsContext.drivers?.find(
        (d) => d.id === roundtrip.driver,
      ) || {
        fullName: '',
        id: '',
      },
      vehicle: roundtripsMapingsContext.vehicles?.find(
        (v) => v.id === roundtrip.vehicle,
      ) || {
        fullName: '',
        id: '',
      },
    },
  });
  const {
    control,
    watch,
    // formState: { errors },
    // setError,
  } = methods;

  // Watch all form values
  const formValues = watch();

  const [addRoundtripMapping] = useAddRoundtripMappingMutation();
  // Log form values to the console whenever they change
  useEffect(() => {
    const addRoundtrip = async () => {
      if (roundtrip.id.includes('New') && formValues.roundtripCode.value) {
        await addRoundtripMapping({
          _roundtripGescom: formValues.roundtripCode.value,
          _driver: formValues.driver.id || null,
          _vehicle: formValues.vehicle.id || null,
          _deck: warehouseId,
        });
      }
    };
    try {
      addRoundtrip();
    } catch (e) {
      console.log(e);
    }
  }, [roundtrip.id, formValues.roundtripCode.value]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">{`R${index + 1}`}</Stack>
      {/*<Stack>*/}
      {/*  <Controller*/}
      {/*    name="area"*/}
      {/*    control={control}*/}
      {/*    render={({ field }) => (*/}
      {/*      <AreaAutoComplete*/}
      {/*        field={field}*/}
      {/*        areas={[*/}
      {/*          {*/}
      {/*            name: 'paris',*/}
      {/*            _id: '66cdf11d5ed5d2ade92f9289',*/}
      {/*          },*/}
      {/*        ]}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</Stack>*/}
      <Stack>
        <Controller
          name="roundtripCode"
          control={control}
          render={({ field }) => (
            <AvailableInternalCodeAutoComplete
              field={field}
              warehouseId={warehouseId}
              roundtrip={roundtrip}
            />
          )}
        />
      </Stack>
      <Stack>
        <Controller
          name="driver"
          control={control}
          render={({ field }) => (
            <DriverAutoComplete
              field={field}
              warehouseId={warehouseId}
              roundtrip={roundtrip}
            />
          )}
        />
      </Stack>
      <Stack>
        <Controller
          name="vehicle"
          control={control}
          render={({ field }) => (
            <VehiclesAutoComplete
              field={field}
              warehouseId={warehouseId}
              roundtrip={roundtrip}
            />
          )}
        />
      </Stack>
      <Stack>
        <IconButton
          disableRipple
          onClick={async () => {
            if (roundtrip.id.includes('New')) {
              roundtripsMapingsContext.updateDataWithFunction((prev) => {
                prev.roundtripMappings = prev.roundtripMappings.map(
                  (mapping) => {
                    if (mapping.id === warehouseId) {
                      mapping.roundtrips = mapping.roundtrips.filter(
                        (r) => r.id !== roundtrip.id,
                      );
                      mapping.nbOfRoundtrips -= 1;
                    }
                    return mapping;
                  },
                );
              });
              return;
            }
            try {
              roundtripsMapingsContext.updateDataWithFunction((prev) => {
                prev.roundtripMappings = prev.roundtripMappings.map(
                  (warehouse) => {
                    if (warehouse.id === warehouseId) warehouse.loading = true;
                    return warehouse;
                  },
                );
              });
              const res = await deleteMapping({ ids: [roundtrip.id] });
              // @ts-ignore
              if (!res?.error)
                roundtripsMapingsContext.updateDataWithFunction((prev) => {
                  prev.roundtripMappings = prev.roundtripMappings.map(
                    (mapping) => {
                      if (mapping.id === warehouseId) {
                        mapping.roundtrips = mapping.roundtrips.filter(
                          (r) => r.id !== roundtrip.id,
                        );
                        mapping.nbOfRoundtrips -= 1;
                      }
                      return mapping;
                    },
                  );
                });
            } catch (e) {
              console.log(e);
            } finally {
              roundtripsMapingsContext.updateDataWithFunction((prev) => {
                prev.roundtripMappings = prev.roundtripMappings.map(
                  (warehouse) => {
                    if (warehouse.id === warehouseId) warehouse.loading = false;
                    return warehouse;
                  },
                );
              });
            }
          }}
        >
          <Icon color={themes.default.errorMain} icon={<MinusCircle />} />
        </IconButton>
      </Stack>
    </>
  );
}

export default RoundtripFormRow;
