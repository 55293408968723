import { SectionTitle } from 'app/components/Form/styles';
import { Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'common/hooks/useDebounce';
import { useUpdateCustomerMutation } from 'common/services/customerApi';
import { StatusCustomer } from 'app/components/StatusCustomer';
import { usePlaceContext } from '../../../hooks/usePlaceContext';
import { CustomerStatus } from 'common/types/Customer';
import { isEmpty, omit } from 'lodash';
import { Address } from 'types';
import LocationPicker from 'app/components/LocationPicker';
import {
  LeftSection,
  MainSection,
  RightSection,
  TabCard,
} from 'app/styledComponents/DetailsSection.styled';
import FormTextField from 'app/components/Form/TextField';
import { hasUpdatedValues } from 'app/helpers/helpers';
import { MainDetails } from 'app/pages/Customers/components/Form/components/MainDetails';
import { ClientInformation } from 'app/pages/Customers/components/Form/components/ClientInformation';
import { ContactInformation } from 'app/pages/Customers/components/Form/components/ContactInformation';
import { ContactDetails } from 'app/pages/Customers/components/Form/components/ContactDetails';
import { BusinessInformation } from 'app/pages/Customers/components/Form/components/BusinessInformation';
import { ClientLogo } from 'app/pages/Customers/components/Form/components/ClientLogo';

export const InfoForm = ({ customerDetail }) => {
  const { canEdit } = usePlaceContext();

  const [mainlocationValue, setMainLocationvalue] = useState<Address>({
    name: customerDetail?.mainLocation?.name || '',
    city: customerDetail?.mainLocation?.city || '',
    department: customerDetail?.mainLocation?.department || '',
    region: customerDetail?.mainLocation?.region || '',
    continent: customerDetail?.mainLocation?.continent || '',
    zipCode: customerDetail?.mainLocation?.zipCode || '',
    formattedAddress: customerDetail?.mainLocation?.formattedAddress || '',
    shortAddress: customerDetail?.mainLocation?.shortAddress || '',
    countryCode: customerDetail?.mainLocation?.countryCode || '',
    geometry: {
      type: customerDetail?.mainLocation?.geometry?.type || '',
      coordinates: customerDetail?.mainLocation?.geometry?.coordinates || [],
    },
  });

  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      emailInput: '',
      mainEmailAddress: customerDetail?.email ?? '',
      code: customerDetail?.code ?? '',
      publicName: customerDetail?.publicName ?? '',
      legalName: customerDetail?.legalName ?? '',
      email: customerDetail?.email ?? '',
      otherEmails: customerDetail?.otherEmails ?? [],
      vatNumber: customerDetail?.vatNumber ?? '',
      invoicePeriod: customerDetail?.invoicePeriod ?? '',
      _customerType: customerDetail?._customerType ?? '',
      _salesman: customerDetail?._salesman?.id ?? '',
      status: customerDetail?.status ?? '',
      managerName: customerDetail?.managerName ?? '',
      managerName2: customerDetail?.managerName2 ?? '',
      mainLocation: customerDetail?.mainLocation ?? {},
      switchState: customerDetail?.switchState ?? '',
      legalIdAlt: customerDetail?.legalIdAlt ?? '',
      salePhone: customerDetail?.salePhone ?? '',
      type: customerDetail?.type ?? '',
      notes: customerDetail?.notes ?? '',
      mobilePhone: customerDetail?.mobilePhone ?? '',
      mobilePhone2: customerDetail?.mobilePhone2 ?? '',
    },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors, isValidating },
  } = methods;

  const type = watch('type');
  const code = watch('code');
  const publicName = watch('publicName');
  const legalName = watch('legalName');
  const vatNumber = watch('vatNumber');
  const managerName2 = watch('managerName2');
  const legalIdAlt = watch('legalIdAlt');
  const salesman = watch('_salesman');
  const status = watch('status');
  const managerName = watch('managerName');
  const customerType = watch('_customerType');
  const mobilePhone = watch('mobilePhone');
  const mobilePhone2 = watch('mobilePhone2');
  const salePhone = watch('salePhone');
  const notes = watch('notes');

  const [updateCustomer] = useUpdateCustomerMutation();

  const payload = useMemo(() => {
    return {
      id: customerDetail?._id,
      publicName: publicName,
      vatNumber: vatNumber,
      managerName2: managerName2,
      legalName: legalName,
      status: status,
      _salesman: salesman?.value || customerDetail?._salesman?.id,
      _customerType: customerType,
      mobilePhone: mobilePhone,
      mobilePhone2: mobilePhone2,
      managerName: managerName,
      mainLocation: mainlocationValue,
      salePhone: salePhone,
      type: type,
      notes,
    };
  }, [
    customerDetail?._id,
    customerDetail?._salesman?.id,
    publicName,
    vatNumber,
    managerName2,
    legalName,
    status,
    salesman?.value,
    customerType,
    mobilePhone,
    mobilePhone2,
    managerName,
    mainlocationValue,
    salePhone,
    type,
    notes,
  ]);

  const debouncedPayload = useDebounce(payload, 300);

  function handleSaveCustomerData() {
    const updatedValues = omit(payload, ['mainLocation']);
    const customerValues = omit(customerDetail, ['mainLocation']);
    if (
      !isEmpty(errors) ||
      (!hasUpdatedValues(updatedValues, {
        ...customerValues,
        _salesman: customerDetail?._salesman?.id,
      }) &&
        !hasUpdatedValues(
          omit(payload?.mainLocation, ['geometry']),
          omit(customerDetail?.mainLocation, ['geometry']),
        ))
    ) {
      return;
    } else {
      updateCustomer(debouncedPayload);
    }
  }

  function handleSaveSiren() {
    if (legalIdAlt !== customerDetail?.legalIdAlt) {
      updateCustomer({ id: customerDetail._id, legalIdAlt });
    }
  }
  function handleSaveCode() {
    if (code !== customerDetail?.code) {
      updateCustomer({ id: customerDetail._id, code });
    }
  }

  useEffect(() => {
    if (canEdit && !isValidating && isEmpty(errors.code)) {
      handleSaveCode();
    }
  }, [canEdit, code, errors.code, isValidating]);
  useEffect(() => {
    if (canEdit && !isValidating && isEmpty(errors.legalIdAlt)) {
      handleSaveSiren();
    }
  }, [canEdit, errors.legalIdAlt, isValidating, legalIdAlt]);
  useEffect(() => {
    if (canEdit) {
      handleSaveCustomerData();
    }
  }, [debouncedPayload]);

  const customerStatus: any = [];
  Object.values(CustomerStatus).forEach((status) => {
    customerStatus.push({
      label: t(`customer.status.${status}`),
      value: status,
      render: () => <StatusCustomer status={status} />,
    });
  });

  return (
    <MainSection>
      <LeftSection>
        <Typography fontSize="0.875rem" fontWeight={500}>
          {t('info')}
        </Typography>

        <Typography fontSize="0.75rem">
          {t('customer.info_tab_text')}
        </Typography>
      </LeftSection>

      <RightSection>
        <TabCard height="auto">
          <MainDetails control={control} errors={errors} />
        </TabCard>
        <TabCard height="auto">
          <SectionTitle>{t('common.address')}</SectionTitle>
          <LocationPicker
            defaultAddress={mainlocationValue}
            onChange={(address) => {
              !!address && setMainLocationvalue(address);
            }}
            mapContainerSx={{ height: '150px' }}
            disabled={!canEdit}
          />
        </TabCard>
        <TabCard height="auto">
          <ClientInformation control={control} errors={errors} />
        </TabCard>
        <TabCard height="auto">
          <ContactInformation
            control={control}
            errors={errors}
            reset={() => {
              setValue('emailInput', '');
            }}
          />
        </TabCard>
        <TabCard height="auto">
          <ContactDetails control={control} />
        </TabCard>
        <TabCard height="auto">
          <BusinessInformation control={control} />
        </TabCard>
        <TabCard height="auto">
          <SectionTitle>{t('common.notes')}</SectionTitle>
          <FormTextField
            name="notes"
            label="common.notes"
            control={control}
            disabled={!canEdit}
            multiline
            rows={4}
          />
        </TabCard>
        <TabCard height="auto">
          <ClientLogo />
        </TabCard>
      </RightSection>
    </MainSection>
  );
};
