export enum RoundtripStatus {
  PENDING = 'pending',
  PLANNED = 'planned',
  CANCELED = 'canceled',
  ON_WAY = 'on-way',
  PAUSED = 'paused',
  COMPLETED = 'completed',
  PREPARED = 'prepared',
  PARKED = 'parked',
  UNLOADED = 'unloaded',
  LOADED = 'loaded',
  LOADING = 'loading',
  ON_DOCK = 'on-dock',
  UNLOADING = 'unloading',
}

export enum RoundtripStopType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
  FUEL = 'fuel',
  MAINTENANCE = 'maintenance',
  EMERGENCY = 'emergency',
  VISIT = 'visit',
  EXPRESS = 'express',
  REPAIR = 'repair',
}

export enum RoundtripStopStatus {
  TO_BE_PREPARED = 'to-be-prepared',
  PREPARED = 'prepared',
  PLANNED = 'planned',
  LOADED = 'loaded',
  PENDING = 'pending',
  CANCELED = 'canceled',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  UNLOADING = 'unloading',
  UNLOADED = 'unloaded',
}

export enum OrderStatus {
  INITIATED = 'initiated',
  INITIATED_CUSTOMER = 'initiated-customer',
  PENDING = 'pending', // pending (bianta) et To be reviewed (ordoria)
  PENDING_CUSTOMER = 'pending-customer', // pending update from client
  VALIDATED = 'validated', // validated by sales
  CONFIRMED = 'confirmed', // confirmed by logistic - to be prepared
  PREPARED = 'prepared',
  LOADED = 'loaded',
  UNLOADED = 'unloaded',
  READY_FOR_DELIVERY = 'ready-for-delivery', // When roundtrip is planned and cluster is active
  ON_THE_ROAD = 'on-the-road',
  DELIVERED = 'delivered',
  CANCELED = 'canceled',
  BLOCKED = 'blocked',
  UNLOADING = 'unloading',
}

export enum OrderType {
  COLLECTION = 'collection',
  DELIVERY = 'delivery',
  EXPRESS = 'express',
  URGENT = 'urgent',
  REPAIR = 'repair',
}

export enum OrderSource {
  PUBLIC_API = 'PUBLIC_API',
  ORDORIA = 'ORDORIA',
  GESCOM = 'GESCOM',
}

export enum OrderPriority {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum LeaveRequestCategory {
  TEAM_REQUEST = 'Team Request',
  MY_REQUEST = 'My requests',
}

export enum LeaveRequestStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
}

export enum TruckStatus {
  ACTIVE = 'active',
  OFFLINE = 'offline',
  AVAILABLE = 'available',
  EMERGENCY = 'emergency',
  RETURNING = 'returning',
}

export enum ToolsStatus {
  INUSE = 'in use',
  AVAILABLE = 'available',
}

export enum VehiclesStatus {
  AVAILABLE = 'available',
  INUSE = 'in-use',
  OUTOFSERVICE = 'out-of-service',
}

export enum SupportUnitType {
  SUPPORTUNIT = 'supportunit',
  SUPPORTUNITLOCATION = 'supportunitlocation',
}

export enum NotificationType {
  NEW_LEAVE_REQUEST = 'new-leave-request',
  REQUEST_TO_UNLOAD = 'request-to-unload',
}
