import React from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useUpdateRoundtripMappingMutation } from '../../../../../../common/services/roundtripMappings';
import { IRoundtripMappingsContext } from '../../index';

function VehiclesAutoComplete({ field, warehouseId, roundtrip }) {
  const { vehicles, updateDataWithFunction } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');
  const [updateRoundtripMappings] = useUpdateRoundtripMappingMutation();
  const handleBlur = (selection) => {
    if (roundtrip.vehicle !== selection.id)
      try {
        updateRoundtripMappings({
          id: roundtrip.id,
          body: {
            _roundtripGescom: roundtrip.roundtripCode,
            _vehicle: selection.id || null,
            _deck: warehouseId,
          },
        }).then(() => {
          updateDataWithFunction((prev) => {
            prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
              if (warehouse.id === warehouseId) warehouse.loading = false;
              return warehouse;
            });
          });
        });
        updateDataWithFunction((prev) => {
          prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
            if (warehouse.id === warehouseId) warehouse.loading = true;
            return warehouse;
          });
        });
      } catch (e) {
        console.error('Error updating roundtrip mappings:', e);
        updateDataWithFunction((prev) => {
          prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
            if (warehouse.id === warehouseId) warehouse.loading = false;
            return warehouse;
          });
        });
      }
  };
  return (
    <Stack>
      <Autocomplete
        // open={open}
        // onOpen={() => {
        //   setOpen(true);
        //   getAvailableInternalCodes('671f2beb9485bed5a39f5014');
        // }}
        // onClose={() => setOpen(false)}
        // loading={isLoading}
        {...field}
        options={vehicles || []}
        getOptionLabel={(option: { fullName: string; id: string }) =>
          option.fullName
        }
        value={
          field.value || {
            fullName: '',
            id: '',
          }
        }
        onChange={(event, newValue) => {
          field.onChange(newValue);
          handleBlur(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Vehicles"
            margin="normal"
            value={field.value.id}
            size="small"
            variant="outlined"
            label="Vehicles"
            // onBlur={handleBlur}
            InputProps={{
              ...params.InputProps,
              // endAdornment: (
              //   <>
              //     {isLoading ? (
              //       <CircularProgress color="inherit" size={20} />
              //     ) : null}
              //     {params.InputProps.endAdornment}
              //   </>
              // ),
            }}
          />
        )}
      />
    </Stack>
  );
}
export default VehiclesAutoComplete;
