import { Autocomplete, Grid, TextField } from '@mui/material';
import { PhoneInputField } from 'app/components/Form/PhoneInputField';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import { useContext, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormTextField from 'app/components/Form/TextField';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';
import { getAgent } from 'common/helpers/agent';
import { useDebounce } from 'common/hooks/useDebounce';
import { CustomerFormcontext } from '../../context';

export const ContactDetails = ({ control }) => {
  const { t } = useTranslation();
  const { canEdit, customer } = useContext(CustomerFormcontext);

  const [phone, setPhone] = useState('');

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const { data: salesData, isFetching } = useGetUsersBasicQuery(
    `?roles=sales&isArchived=false&text=${debouncedSearch}&limit=50`,
  );
  const organization = useSelector(selectOrganization);

  const salesPhone = useMemo(() => {
    if (!!phone) {
      return phone;
    }
    const agent = getAgent(customer?._salesman, organization);
    return agent?.companyPhone || '';
  }, [customer?._salesman, organization, phone]);

  const salesProps = useMemo(() => {
    const options = salesData?.docs
      ? [...salesData?.docs, customer?._salesman]
      : [customer?._salesman];

    const props = {
      options:
        options?.map((user) => {
          const agent = getAgent(user, organization);
          return {
            value: user?._id,
            label: `${agent?.firstName + ' ' + agent?.lastName}`,
            phone: agent?.companyPhone || '',
          };
        }) || [],
      getOptionLabel: (option) => option?.label,
    };

    if (!!customer) {
      props['value'] = {
        value: customer?._salesman?._id || null,
        label: !!customer
          ? `${
              customer?._salesman?.firstName +
              ' ' +
              customer?._salesman?.lastName
            }`
          : '',
      };
    }
    return props;
  }, [customer?._salesman, salesData?.docs]);

  return (
    <FormSection>
      <SectionTitle>{t('contact_details')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <Controller
            name="mobilePhone"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                specialLabel={t('mobile_phone')}
                disabled={!canEdit}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="mobilePhone2"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                specialLabel={t('secondary_mobile_phone')}
                disabled={!canEdit}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="managerName"
            label="manager"
            control={control}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="managerName2"
            label="secondary_manager"
            control={control}
            disabled={!canEdit}
          />
        </Grid>
      </Grid>

      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <Controller
            name="_salesman"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                {...salesProps}
                autoComplete={false}
                onChange={(e, value) => {
                  field.onChange(value);
                  setPhone(value?.phone || '+33');
                }}
                onBlur={() => setSearch('')}
                loading={isFetching}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    label={t('sales')}
                    variant="outlined"
                    onChange={(e) => {
                      setSearch(e?.target?.value);
                    }}
                    required
                  />
                )}
                disabled={!canEdit}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="salePhone"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                specialLabel={t('sales_phone')}
                disabled={true}
                value={salesPhone}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
