import React from 'react';
import GridContext from '../../../../../components/ManagedGrid/GridContext';
import {
  Permission,
  usePermission,
} from '../../../../../../hooks/Abilities/usePermission';
import FunctionalWarehouses from './components/FunctionalWarehouses';
import { CUSTOM_WAREHOUSES_COLUMN_CONFIG } from './config';

function WarehousesV2() {
  const can = usePermission('users');

  return (
    <GridContext
      data={[]}
      customColumnConfig={CUSTOM_WAREHOUSES_COLUMN_CONFIG}
      leftPins={1}
      rightPins={2}
      title="modules.warehouses"
      canEdit={can(Permission.EDIT)}
      canExport={can(Permission.EXPORT)}
    >
      <FunctionalWarehouses />
    </GridContext>
  );
}
export default WarehousesV2;
