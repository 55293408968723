import React, { useState } from 'react';
import { IHeaderParams } from '@ag-grid-community/core';
import { Icon } from '../../Icon';
import { CaretDown, CaretUp, DotsThree, PushPin } from '@phosphor-icons/react';
import { themes } from '../../../../styles/theme/themes';
import { Menu, Stack, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../styles/theme/slice/selectors';
import MenuItem from '@mui/material/MenuItem';
import { IColDef, IGridContext } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';

// @ts-ignore
interface HeaderComponentProps extends IHeaderParams {
  displayName: string;
  enableSorting: boolean;
  column: {
    isSortAscending: () => boolean;
    colDef: IColDef;
    isSortDescending: () => boolean;
    addEventListener: (event: string, callback: () => void) => void;
    removeEventListener: (event: string, callback: () => void) => void;
  };
}

const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  const [sorted, setSorted] = useState<'asc' | 'desc' | null>(null);
  React.useEffect(() => {
    const onSortChanged = () => {
      if (props.column.isSortAscending()) {
        setSorted('asc');
      } else if (props.column.isSortDescending()) {
        setSorted('desc');
      } else {
        setSorted(null);
      }
    };

    props.column.addEventListener('sortChanged', onSortChanged);
    return () => props.column.removeEventListener('sortChanged', onSortChanged);
  }, [props.column]);

  const onSortRequested = (event: React.MouseEvent) => {
    props.setSort(
      sorted === null ? 'asc' : sorted === 'asc' ? 'desc' : null,
      event.shiftKey,
    );
  };
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      onClick={props.enableSorting ? onSortRequested : undefined}
    >
      <Stack direction="row" gap="2px" alignItems="center">
        {props.column.colDef.pinned && props.column.colDef.headerName && (
          <UnpinMenu
            currentColumnField={props.column.colDef.field!}
            currentPin={props.column.colDef.pinned}
          />
        )}
        <span>{t(props.displayName)}</span>
        {props.enableSorting && (
          <CustomSortIconWrapper>
            <Icon
              icon={<CaretUp />}
              weight="bold"
              size={12}
              color={
                sorted === 'asc'
                  ? themes?.default?.black
                  : `${themes?.default?.iconColor}99`
              }
            />
            <Icon
              icon={<CaretDown />}
              weight="bold"
              size={12}
              color={
                sorted === 'desc'
                  ? themes?.default?.black
                  : `${themes?.default?.iconColor}99`
              }
            />
          </CustomSortIconWrapper>
        )}
      </Stack>
      {!props.column.colDef.pinned && (
        <PinningMenu currentColumnField={props.column.colDef.field!} />
      )}
    </Stack>
  );
};

function UnpinMenu({
  currentColumnField,
  currentPin,
}: {
  currentColumnField: string;
  currentPin: 'left' | 'right' | boolean | null | undefined;
}) {
  const theme = useSelector(selectTheme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const grid = useManagedContext<IGridContext>('grid');
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        size="small"
        sx={{
          padding: '0',
          background: 'transparent',
          borderRadius: '4px',
          marginRight: '4px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <PushPin />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(e) => {
          // @ts-ignore
          e.stopPropagation();
          handleClose();
        }}
        anchorOrigin={{
          vertical: 20,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          borderRadius: '4px',
          boxShadow: themes.default.menuBoxShadow,
        }}
        PaperProps={{
          style: {
            width: 'fit-content',
            padding: '1px',
          },
        }}
        MenuListProps={{
          style: {
            padding: '1px',
          },
        }}
        elevation={1}
      >
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          sx={{
            gap: '4px',
            '&:hover': {
              background: `${theme.lightBackground}`,
            },
          }}
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            grid.api.pinColumn(currentColumnField, undefined);
          }}
        >
          {currentPin === 'right' ? t('unpin.right') : t('unpin.left')}
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          sx={{
            gap: '4px',
            '&:hover': {
              background: `${theme.lightBackground}`,
            },
          }}
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            if (currentPin === 'right')
              grid.api.pinColumn(currentColumnField, 'left');
            else grid.api.pinColumn(currentColumnField, 'right');
          }}
        >
          {currentPin === 'right' ? t('pin.left') : t('pin.right')}
        </MenuItem>
      </Menu>
    </>
  );
}

function PinningMenu({ currentColumnField }: { currentColumnField: string }) {
  const theme = useSelector(selectTheme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const grid = useManagedContext<IGridContext>('grid');
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        sx={{
          borderRadius: '4px',
          background: themes.default.accordionWhiteBg,
          width: '16px',
          height: '16px',
          padding: '0 !important',
        }}
      >
        <DotsThree size={10} color={themes.default.grey2} weight="bold" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(e) => {
          // @ts-ignore
          e.stopPropagation();
          handleClose();
        }}
        anchorOrigin={{
          vertical: 20,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          borderRadius: '4px',
          boxShadow: themes.default.menuBoxShadow,
        }}
        PaperProps={{
          style: {
            width: 'fit-content',
            padding: '1px',
          },
        }}
        MenuListProps={{
          style: {
            padding: '1px',
          },
        }}
        elevation={1}
      >
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          sx={{
            gap: '4px',
            '&:hover': {
              background: `${theme.lightBackground}`,
            },
          }}
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            grid.api.pinColumn(currentColumnField, 'left');
          }}
        >
          {t('pin.left')}
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          sx={{
            gap: '4px',
            '&:hover': {
              background: `${theme.lightBackground}`,
            },
          }}
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            grid.api.pinColumn(currentColumnField, 'right');
          }}
        >
          {t('pin.right')}
        </MenuItem>
      </Menu>
    </>
  );
}

const CustomSortIconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 16px;
  margin: 0 0 0 8px;
  position: relative;

  div {
    height: 6px;
    position: absolute;
    left: 0;
  }

  div:first-child {
    top: -7px;
  }

  div:last-child {
    bottom: 11px;
  }
`;

export default HeaderComponent;
