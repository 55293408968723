import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import _ from 'lodash';
import { useState } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'app/components/CustomCheckbox';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';

export type SelectFieldProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  data: { value: string | number; label: string }[] | {}[];
  checkboxes?: boolean;
  renderValue?: any;
  multiple?: boolean;
  values?: any;
  required?: boolean;
  disabled?: boolean;
};

export const FormSelectField = <T extends FieldValues>(
  props: SelectFieldProps<T>,
) => {
  const controllerProps = _.omit(props, ['label', 'data']);
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController(controllerProps);
  const { formatError } = useErrorFormatter();
  const { t } = useTranslation();

  const label = `${t(props.label)} ${!!props.required ? '*' : ''}`;

  const [selected, setSelected] = useState<any>(props.values);

  const handleSelect = (value: any) => {
    setSelected((prevSelectedValues: any[]) =>
      prevSelectedValues.includes(value)
        ? prevSelectedValues.filter((v) => v !== value)
        : [...prevSelectedValues, value],
    );
  };

  return (
    <ManagedTooltip
      disabled={!props.disabled}
      title={selected?.map((d) => (props.checkboxes ? d : d.label))?.join(', ')}
      style={{ flex: 1 }}
    >
      <FormControl fullWidth error={!!error} disabled={!!props.disabled}>
        <InputLabel id={props.name} size="small">
          {label}
        </InputLabel>
        <Select
          size="small"
          name={props.name}
          defaultValue={props.values}
          required={props.required}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          value={value}
          renderValue={props.renderValue}
          multiple={props.multiple}
          disabled={!!props.disabled}
        >
          {props.checkboxes
            ? props.data.map((d, i) => (
                <MenuItem
                  key={i}
                  value={d}
                  onClick={() => {
                    handleSelect(d);
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox checked={selected?.includes(d)} />
                  <ListItemText primary={d} />
                </MenuItem>
              ))
            : props.data.map((d, i) => (
                <MenuItem key={`${props.name}-${i}`} value={d.value}>
                  {d.label}
                </MenuItem>
              ))}
        </Select>
        {error && <FormHelperText>{formatError(error)}</FormHelperText>}
      </FormControl>
    </ManagedTooltip>
  );
};

export default FormSelectField;
