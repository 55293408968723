import { To, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

// navigation links
import { ALL_ROUNDTRIPS, SCHEDULED_ROUNDTRIPS, ROUNDTRIP } from 'utils/routes';

// icons
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { themes } from 'styles/theme/themes';
import { GRID, MAP } from '../../TableComponent/constants';
import { TableViewPopover } from '../../../../styles/components/muitable/TableViewPopover';
import DropdownDatePicker from 'app/components/DatePicker/DropdownDatePicker';
import { selectMode } from 'app/slices/tools/selectors';
import { EnhancedTableToolbar } from 'app/components/EnhancedTableToolbar';
import {
  GridNine,
  MapPin,
  RoadHorizon,
  Table as TableIcon,
  Check,
} from '@phosphor-icons/react';
import { TabsContext } from 'app/pages/RoundtripsTabs';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import { useDispatch } from 'react-redux';
import { toolsActions } from 'app/slices/tools';
import { ZoomButton } from 'app/components/EnhancedTableToolbar/components/ZoomButton';
import { AddIconButton } from 'app/components/AddIconButton';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import { Modals } from 'app/pages/RoundTrips/components/NoData/Components/Modals';
import If from 'app/components/If';
import { TabsMenu } from 'app/components/TabsMenu';
import { Icon } from 'app/components/Icon';
import { useAbility } from 'hooks/Abilities';
import { useTabs, TabType } from 'app/components/TabsMenu/hooks/useTabs';

export const Headers = () => {
  const ability = useAbility();
  const today = dayjs().startOf('day');

  const dispatch = useDispatch();
  const location = useLocation();

  // hooks
  const {
    dateValue,
    setDateValue,
    search,
    setSearch,
    heads,
    headCells,
    setHeadCells,
    warehouseName,
    warehouseMainId,
    setWarehouseMainId,
    viewMode,
    emptyData,
    anchorEl,
    setAnchorEl,
    tab,
    setTab,
  } = useContext(TabsContext);
  const selectedMode = useSelector(selectMode);
  const { t } = useTranslation();
  const navigate = useNavigate();

  //states
  const [mode, setMode] = useState<
    'grid' | 'track' | 'excel' | 'map' | 'reoptimize'
  >(selectedMode || 'grid');

  // functions
  const handleOnSwitchTab = (
    event: { preventDefault: () => void },
    tab: To,
  ) => {
    event.preventDefault();
    setMode('grid');
    setTab(tab);
    dispatch(toolsActions.setMode({ mode: 'grid' }));
    navigate(tab);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setWarehouseMainId(null);
  };

  const defaultTabs: TabType[] = useMemo(() => {
    return [
      {
        value: SCHEDULED_ROUNDTRIPS,
        label: (
          <>
            {viewMode ? (
              <Icon icon={<Check weight="bold" />} />
            ) : (
              <Icon icon={<RoadHorizon />} />
            )}
            {viewMode
              ? t('nav.appHeader.completed')
              : dateValue.isAfter(today)
                ? t('nav.appHeader.scheduled')
                : t('nav.appHeader.running')}
          </>
        ),
      },
      {
        value: ALL_ROUNDTRIPS,
        label: (
          <>
            <Icon icon={<GridNine />} />
            {t('nav.appHeader.all_results')}
          </>
        ),
      },
    ];
  }, [SCHEDULED_ROUNDTRIPS, ALL_ROUNDTRIPS, viewMode, dateValue]);

  const [tabs, setTabs] = useTabs({
    defaultTabs: defaultTabs,
    moduleName: 'roundtrips',
  });

  return (
    <>
      <EnhancedTableToolbar
        headCells={headCells}
        setHeadCells={setHeadCells}
        heads={heads}
        title={t(`nav.mainNav.roundtrips`)}
        search={search}
        setSearch={tab === SCHEDULED_ROUNDTRIPS ? setSearch : null}
        leftMenu={
          <>
            <DropdownDatePicker
              dateValue={dateValue}
              setDateValue={setDateValue}
              disablePast={false}
              showDots={true}
              inputFormat="MMM D, YYYY"
            />
            <VerticalDivider sx={{ mx: '16px' }} />
            <TabsMenu
              handleOnSwitchTab={handleOnSwitchTab}
              selectedTab={tab}
              defaultTabs={defaultTabs}
              tabs={tabs}
              setTabs={setTabs}
            />
          </>
        }
        actionsComponents={
          <>
            <If condition={tab === SCHEDULED_ROUNDTRIPS}>
              <TableViewPopover
                views={
                  location.pathname === `${ROUNDTRIP}/${ALL_ROUNDTRIPS}`
                    ? []
                    : [
                        {
                          type: MAP,
                          icon: (
                            <MapPin
                              size={16}
                              color={themes?.default?.iconColor}
                            />
                          ),
                          label: t('map'),
                        },
                        {
                          type: GRID,
                          icon: (
                            <TableIcon
                              size={16}
                              color={themes?.default?.iconColor}
                            />
                          ),
                          label: t('grid'),
                        },
                      ]
                }
                tableType={mode}
                handleAction={(_e, action) => {
                  // @ts-ignore
                  setMode(action);
                  dispatch(toolsActions.setMode({ mode: action }));
                }}
              />
            </If>

            <ZoomButton />

            <If
              condition={
                !viewMode && !emptyData && ability.can('canedit', 'roundtrips')
              }
            >
              <VerticalDivider />
              <AddIconButton
                onClick={(e) => {
                  handlesetAnchorFunction(e, setAnchorEl);
                }}
              />
            </If>
          </>
        }
      />

      {dateValue && (
        <Modals
          warehouseId={warehouseName}
          warehouseMainId={warehouseMainId}
          anchorEl={anchorEl}
          handleClose={handleClose}
          allRoundtrip={tab === ALL_ROUNDTRIPS}
        />
      )}
    </>
  );
};
