export function getColumnDefs<U extends object>(
  memoData: U[],
  leftPins,
  rigthPins,
  customColumnConfig,
) {
  if (!memoData.length) return [];
  return Object.keys(memoData[0])
    .filter((key) => key !== '__typename' && key !== 'id')
    .map((key, index) => {
      return {
        field: key,
        headerName: key.startsWith('__')
          ? ''
          : key.split('_').join(' ').toUpperCase(),
        unSortIcon: !!customColumnConfig?.[index]?.sortField,
        sortable: !!customColumnConfig?.[index]?.sortField,
        pinned:
          index < leftPins
            ? 'left'
            : index > Object.keys(memoData[0]).length - rigthPins - 2
              ? 'right'
              : (null as 'left' | 'right' | null | undefined),
        ...customColumnConfig[index],
      };
    });
}
