//import sections
import { useUploadMutation } from 'common/services/files';
import {
  Form,
  FormActions,
  FormSection,
  FormWrapper,
  SectionTitle,
} from 'app/components/Form/styles';
import {
  FormControlLabel,
  Box,
  Typography,
  Stack,
  TextField,
  IconButton,
  Button,
} from '@mui/material';

import { SubmitButton } from 'app/pages/Accessories/Components/AccessoryForm/Components/SubmitButton';
import { themes } from 'styles/theme/themes';
import { Switch } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { Trash, Flag, PlusCircle } from '@phosphor-icons/react';
import { usePostCustomerMutation } from 'common/services/customerApi';

import If from 'app/components/If';
import { CustomerStatus as CustomerStatusEnum } from 'common/types/Customer';
import LocationPicker from 'app/components/LocationPicker';
import { Address } from 'types';
import { ImageUpload } from 'app/components/Form/ImageUpload';
import Each from 'common/UtilityComponents/Each';
import { MainTypo } from 'app/pages/Ordoria/CallPlan/components/CallDetailsDrawer/components/Info/styles';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { MainDetails } from '../Form/components/MainDetails';
import { ClientInformation } from '../Form/components/ClientInformation';
import { EmailRow } from '../Form/components/ContactInformation';
import { ContactDetails } from '../Form/components/ContactDetails';
import { BusinessInformation } from '../Form/components/BusinessInformation';
import { CustomerFormcontext } from '../Form/context';
import { useToaster } from 'hooks/useToaster';
import { isEmpty } from 'lodash';
interface Props {
  onClose: () => void;
  customer: any;
  afterSubmit: any;
}

export const AddCustomers: React.FC<Props> = ({ onClose, afterSubmit }) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      type: 'client',
      code: '',
      publicName: '',
      legalName: '',
      email: '',
      otherEmails: '',
      vatNumber: '',
      invoicePeriod: '',
      mobilePhone: '',
      mobilePhone2: '',
      _customerType: '',
      _salesman: { value: '', label: '', phone: '' },
      status: CustomerStatusEnum.IN_REVIEW,
      locations: '',
      managerName: '',
      managerName2: '',
      mainLocation: '',
      switchState: '',
      legalIdAlt: '',
      salePhone: '',
      emailInput: '',
    },
  });

  const {
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isValidating },
    getValues,
  } = methods;
  const { t } = useTranslation();
  const toast = useToaster();
  const [Addcustomer, { isLoading: isAdding }] = usePostCustomerMutation();

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [mainlocationValue, setMainLocationvalue] = useState<Address>({
    name: '',
    city: '',
    department: '',
    region: '',
    continent: '',
    zipCode: '',
    formattedAddress: '',
    shortAddress: '',
    countryCode: '',
    geometry: {
      type: '',
      coordinates: [],
    },
  });
  const [switchStateValue, setSwitchState] = useState<boolean>(false);
  const emailInput = watch('emailInput');

  const handleClose = () => {
    onClose();
  };

  const resetForm = () => {
    setMainLocationvalue({
      name: '',
      city: '',
      department: '',
      region: '',
      continent: '',
      zipCode: '',
      formattedAddress: '',
      shortAddress: '',
      countryCode: '',
      geometry: {
        type: '',
        coordinates: [],
      },
    });
    reset();
    setImage(null);
    setEmails([]);
    setSwitchState(false);
  };

  const onSubmit = async (options) => {
    const salesman = getValues('_salesman');
    if (disabled) {
      return;
    }

    try {
      let logo: { url: string; size: number } | null = null;
      if (image) {
        logo = await uploadImage(image);
      }

      const result: any = await Addcustomer({
        publicName: getValues('publicName'),
        type: getValues('type'),
        code: getValues('code'),
        legalName: getValues('legalName'),
        status: getValues('status'),
        email: emails?.find((em) => !!em.main)?.email,
        otherEmails: emails?.filter((em) => !em.main)?.map((em) => em.email),
        salePhone: salesman?.phone,
        mobilePhone2: getValues('mobilePhone2'),
        mobilePhone: getValues('mobilePhone'),
        vatNumber: getValues('vatNumber'),
        _customerType: getValues('_customerType'),
        _salesman: salesman?.value,
        managerName: getValues('managerName'),
        managerName2: getValues('managerName2'),
        mainLocation: mainlocationValue,
        switchState: switchStateValue,
        legalIdAlt: getValues('legalIdAlt'),
        logo,
      }).unwrap();

      toast(5000, 'success', 'action.customer-add');

      afterSubmit({
        close: options?.closeForm,
        newId: options?.refetch ? result?._id : '',
      });
      if (options?.resetForm) {
        resetForm();
      } else {
        handleClose();
      }
    } catch (err) {}
  };

  const handleChangeswitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState(event.target.checked);
  };

  const [uploadFile, { isLoading: isUploading }] = useUploadMutation();

  const uploadImage = useCallback(
    async (file: File) => {
      if (!file) {
        return null;
      }
      const formData = new FormData();
      formData.append('file', file);
      const { url, size } = await uploadFile({
        formData,
        preserveName: false,
        persist: false,
        objectType: 'customer',
      }).unwrap();

      return { url, size };
    },
    [uploadFile],
  );

  const [emails, setEmails] = useState<{ email: string; main: boolean }[]>([]);

  const { formatError } = useErrorFormatter();
  const theme = useSelector(selectTheme);

  const addEmail = () => {
    const newEmails = [...emails];
    const main = !newEmails?.length;

    setEmails((prev) => [...prev, { email: emailInput, main }]);
    setValue('emailInput', '');
  };

  const removeEmail = (emailToRemove: { email: string; main: boolean }) => {
    let newEmails = [...emails];

    newEmails = newEmails.filter((em) => em.email !== emailToRemove.email);

    if (emailToRemove.main) {
      if (newEmails.length > 0) {
        newEmails[0] = { ...newEmails[0], main: true };
      }
    }

    setEmails(newEmails);
  };

  const toggleMainEmail = (email: { email: string; main: boolean }) => {
    let newEmails = [...emails];

    const currentMain = newEmails.find((em) => em.main);

    if (email.main || (!currentMain && email.main === false)) {
      return;
    }

    newEmails = newEmails.map((em) => {
      if (em.email === email.email) {
        return { ...em, main: true };
      } else if (currentMain && em.email === currentMain.email) {
        return { ...em, main: false };
      }
      return em;
    });

    setEmails(newEmails);
  };

  const disabled = useMemo(() => {
    return !!isEmpty(errors) || !emails?.length || !!isValidating;
  }, [emails?.length, errors, isValidating]);

  return (
    <CustomerFormcontext.Provider
      value={{
        customer: null,
        canEdit: true,
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormWrapper>
          <FormSection>
            <MainDetails control={control} errors={errors} />
          </FormSection>
          <FormSection>
            <ClientInformation control={control} errors={errors} />
          </FormSection>

          <FormSection>
            <SectionTitle>{t('Add_Client_Contact_Information')}</SectionTitle>

            <LocationPicker
              defaultAddress={mainlocationValue}
              onChange={(address) => {
                !!address && setMainLocationvalue(address);
              }}
            />
          </FormSection>

          <FormSection>
            <Box marginY="16px">
              <Stack direction="row" gap="12px" alignItems="flex-start">
                <Controller
                  name="emailInput"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: t('validation.email'),
                    },
                    validate(value) {
                      if (!value) {
                        return true;
                      }

                      return !emails
                        ?.map((em) => em.email)
                        ?.includes(value?.toString());
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="text"
                      label={t('email_address')}
                      variant="outlined"
                      error={!!errors?.emailInput}
                      helperText={formatError(errors?.emailInput)}
                      fullWidth
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
                <IconButton
                  disabled={!emailInput || !!errors?.emailInput}
                  onClick={addEmail}
                >
                  <PlusCircle
                    color={
                      !!emailInput && !errors?.emailInput
                        ? theme.primaryActiveColor
                        : theme.iconColor
                    }
                    size="24"
                    weight="fill"
                  />
                </IconButton>
              </Stack>

              <Each
                of={emails}
                render={(email, index) => {
                  return (
                    <EmailRow
                      key={email.email}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <Stack direction="row" alignItems="center" width="100%">
                        <Stack direction="row" alignItems="center" gap="8px">
                          <Typography
                            sx={{
                              marginLeft: '4px',
                            }}
                          >
                            <>{email.email}</>
                          </Typography>
                          <If condition={email.main}>
                            <MainTypo
                              sx={{
                                backgroundColor: themes.default.primaryLight1,
                                border: `1px solid ${themes.default.strokePrimary}`,
                              }}
                            >
                              {t('main')}
                            </MainTypo>
                          </If>
                        </Stack>

                        <If condition={hoveredIndex === index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            marginLeft="auto"
                          >
                            <If condition={emails?.length > 1}>
                              <ActionIconButton
                                title={
                                  email.main
                                    ? 'customer.main.remove'
                                    : 'customer.main.markAsMain'
                                }
                                onClick={() => toggleMainEmail(email)}
                                icon={<Flag />}
                                iconProps={{
                                  weight: email.main ? 'fill' : 'regular',
                                  color: email.main ? 'goldenrod' : 'inherit',
                                }}
                              />
                            </If>
                            <ActionIconButton
                              title="delete"
                              onClick={() => removeEmail(email)}
                              icon={
                                <Trash color={themes?.default?.chipRedBg} />
                              }
                            />
                          </Stack>
                        </If>
                      </Stack>
                    </EmailRow>
                  );
                }}
              />
            </Box>
          </FormSection>

          <FormSection>
            <ContactDetails control={control} />
          </FormSection>

          <FormSection>
            <BusinessInformation control={control} />
          </FormSection>

          <FormSection>
            <SectionTitle>{t('client_logo')}</SectionTitle>
            <ImageUpload
              imageUrl={image ? URL.createObjectURL(image) : ''}
              description={t('add-client-logo-description')}
              onUpload={(files: File[]) => {
                const file = files?.[0];
                if (!file) return;
                setImage(file);
              }}
              onDelete={() => {
                setImage(null);
              }}
            />
          </FormSection>

          <FormSection>
            <Box marginTop="16px">
              <SectionTitle>{t('Add_Client_Settings')}</SectionTitle>
              <FormControlLabel
                label={t('Add_Client_Show_prices_on_delivery_note')}
                control={
                  <div style={{ marginLeft: '5px' }}>
                    <Switch
                      checked={switchStateValue}
                      onChange={handleChangeswitch}
                      size="small"
                      color="primary"
                    />
                  </div>
                }
              />
            </Box>
          </FormSection>
        </FormWrapper>
        <FormActions>
          <Button
            disableElevation
            sx={{ color: themes.default.black60 }}
            onClick={handleClose}
          >
            {t('common.buttons.cancel')}
          </Button>
          <SubmitButton
            loading={isUploading || isAdding}
            onSubmit={onSubmit}
            disabled={disabled}
            isEdit={false}
            withOptions={true}
          />
        </FormActions>
      </Form>
    </CustomerFormcontext.Provider>
  );
};
